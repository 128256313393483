import React, { useState } from 'react';

export function Collapsible({ children, ...props }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div {...props}>
      {React.Children.map(children, child => 
        React.cloneElement(child, { isOpen, setIsOpen })
      )}
    </div>
  );
}

export function CollapsibleTrigger({ children, isOpen, setIsOpen, ...props }) {
  return (
    <div onClick={() => setIsOpen(!isOpen)} {...props}>
      {children}
    </div>
  );
}

export function CollapsibleContent({ children, isOpen, ...props }) {
  if (!isOpen) return null;
  return <div {...props}>{children}</div>;
}

