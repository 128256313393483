import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LandingPage from './pages/LandingPage';
import Dashboard from './pages/Dashboard';
import TestInterface from './pages/TestInterface';
import Navigation from './components/navigation';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/" />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/test/:testId" element={<PrivateRoute><TestInterface /></PrivateRoute>} />
        <Route path="/test/:testId/review" element={<PrivateRoute><TestInterface isReviewMode={true} /></PrivateRoute>} />
        {/* ... other routes */}
      </Routes>
    </Router>
  );
}

export default App;

