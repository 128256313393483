import heroImage from '../assets/images/4.svg';
import logoImage from '../assets/images/5.svg';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Slider } from '../components/ui/slider';
import { CheckCircle, Star, Users, Brain, TrendingUp, BarChart, ChevronDown, ChevronUp, ArrowRight, Check, Clock, Flag, MessageSquare, Highlighter, Scissors, Play, Eraser } from 'lucide-react';
import { Switch } from '../components/ui/switch';

export default function LandingPage() {
  const [email, setEmail] = useState('');
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);
  const [mcatScore, setMcatScore] = useState({ chem: 123, bio: 123, psych: 123, cars: 123 });
  const [showModal, setShowModal] = useState(false);
  const [activePlan, setActivePlan] = useState('pro');
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState('login'); // 'login' or 'signup'
  const [isAnnual, setIsAnnual] = useState(false);

  const navigate = useNavigate();
  const { loginWithRedirect, logout, isAuthenticated, isLoading, user } = useAuth0();

  const heroRef = useRef(null);
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
    setEmail('');
  };

  const testimonials = [
    { name: "Alex C.", score: "520", quote: "UPangea's question bank is incredibly accurate and detailed, matching AAMC style perfectly. The in-depth explanations truly help you grasp tough concepts." },
    { name: "Sarah L.", score: "515", quote: "The interface is just like the MCAT. The question bank and features are easy to navigate compared to other cluttered platforms." },
    { name: "Michael T.", score: "510", quote: "I've saved so much money with UPangea's affordable pricing. Other question banks cost a fortune for similar quality content!" },
    { name: "Emily R.", score: "511", quote: "UPangea provides the same high-quality questions at a fraction of the cost. Finally, there's a budget-friendly option for MCAT prep without sacrificing value." }
  ];

  const faqItems = [
    { question: "How does UPangea compare to other MCAT question banks in terms of quality?", answer: "Our passages and questions are meticulously crafted to mirror the style and complexity of top competitors, but at a fraction of the cost. We ensure high-quality content without compromising on affordability for students." },
    { question: "Who creates the questions and passages for UPangea?", answer: "All our content is professionally made by industry experts with years of experience. We pride ourselves on offering reliable, top-tier material that students can trust." },
    { question: "How often is the content on UPangea updated?", answer: "We update our question bank daily with new passages and explanations. Additionally, we're constantly adding more features and functionality to enhance your study experience." },
    { question: "Why is UPangea so much cheaper than other MCAT question banks?", answer: "We believe in providing high-quality resources at the lowest possible cost. Our mission is to make premium MCAT prep accessible to every student, without the burden of high expenses." }
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }
    };
  }, []);

  const calculateTotalScore = () => {
    return Object.values(mcatScore).reduce((sum, score) => sum + score, 0);
  };

  const calculatePotentialImprovement = () => {
    const currentTotal = calculateTotalScore();
    const maxPossibleImprovement = 528 - currentTotal;
    const estimatedImprovement = Math.min(Math.round(maxPossibleImprovement * 0.7), 15);
    return currentTotal + estimatedImprovement;
  };

  const scrollTo = (elementRef) => {
    elementRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleAuthAction = (mode) => {
    setAuthMode(mode);
    setShowAuthModal(true);
  };

  const handleAuth = () => {
    navigate('/dashboard');
    setShowAuthModal(false);
  };

  const handleLogin = () => {
    loginWithRedirect();
  };

  const handleSignUp = () => {
    loginWithRedirect({ screen_hint: 'signup' });
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, isLoading, navigate]);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-blue-50 to-green-50">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 px-4 lg:px-6 h-20 flex items-center bg-white shadow-md bg-opacity-90 backdrop-blur-md">
        <Link className="flex items-center justify-center" to="/">
          <img src={logoImage} alt="UPangea Logo" className="h-14 w-14" />
          <span className="ml-1 text-4xl font-bold bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">UPangea</span>
        </Link>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <button onClick={() => scrollTo(featuresRef)} className="text-sm font-medium text-gray-700 hover:text-blue-600 transition-colors">
            Features
          </button>
          <button onClick={() => scrollTo(pricingRef)} className="text-sm font-medium text-gray-700 hover:text-blue-600 transition-colors">
            Pricing
          </button>
          {isLoading ? (
            <Button disabled>Loading...</Button>
          ) : isAuthenticated ? (
            <div className="flex items-center space-x-4">
              <div className="hidden md:flex items-center space-x-2">
                <span className="text-sm font-medium text-gray-700">Welcome,</span>
                <span className="text-sm font-semibold text-blue-600">{user.name}</span>
              </div>
              <Button onClick={() => navigate('/dashboard')} className="bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700">Dashboard</Button>
              <Button onClick={handleLogout} variant="outline" className="text-blue-600 border-blue-600 hover:bg-blue-50">Log Out</Button>
            </div>
          ) : (
            <>
              <Button onClick={handleLogin} variant="outline" className="text-blue-600 border-blue-600 hover:bg-blue-50">Log In</Button>
              <Button onClick={handleSignUp} className="bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700">Sign Up</Button>
            </>
          )}
        </nav>
      </header>

      {/* Main Content */}
      <main className="flex-1 pt-20">
        {/* Hero Section */}
        <section ref={heroRef} className={`w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-gradient-to-br from-blue-600 to-green-600 transition-all duration-1000 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
          <div className="container px-4 md:px-6 mx-auto">
            <div className="flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-12">
              <div className="space-y-4 text-center lg:text-left lg:w-1/2">
                <h1 className="text-4xl font-bold tracking-tighter text-white sm:text-5xl md:text-6xl lg:text-7xl/none xl:text-8xl/none">
                  Master the MCAT with UPangea
                </h1>
                <p className="mx-auto lg:mx-0 max-w-[700px] text-blue-100 md:text-xl">
                  Elevate your MCAT prep with our comprehensive question bank and adaptive learning technology. Achieve your dream score and secure your future in medicine.
                </p>
                <div className="space-x-4 mt-8">
                  <Button 
                    size="lg" 
                    variant="white"
                    className="shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105"
                    onClick={handleSignUp}
                  >
                    Start Free Trial
                  </Button>
                  <Button 
                    size="lg" 
                    variant="outline" 
                    className="text-white border-white hover:bg-white hover:text-blue-600 transition-all duration-300 transform hover:scale-105"
                    onClick={() => scrollTo(featuresRef)}
                  >
                    Learn More
                  </Button>
                </div>
              </div>
              <div className="lg:w-1/2 relative">
                <div className="w-full h-full bg-gradient-to-br from-blue-600 to-green-600 rounded-lg shadow-2xl overflow-hidden transition-all duration-500 transform hover:scale-105 hover:rotate-2">
                  <img 
                    src={heroImage} 
                    alt="MCAT Preparation" 
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="absolute -bottom-4 -right-4 bg-white p-4 rounded-lg shadow-lg">
                  <p className="text-blue-600 font-bold">3,000+ Questions</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" ref={featuresRef} className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight mb-8 text-center text-gray-900">Why Choose UPangea?</h2>
            <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
              {[
                { icon: Brain, title: "Just-in-Time Learning", description: "Our system provides targeted content exactly when you need it, optimizing your study efficiency.", color: "bg-blue-100 text-blue-600" },
                { icon: CheckCircle, title: "Comprehensive Coverage", description: "Over 3,000 MCAT-style questions covering all subjects and question types.", color: "bg-green-100 text-green-600" },
                { icon: Users, title: "Expert-Written Content", description: "Questions and explanations crafted by top MCAT instructors and medical professionals.", color: "bg-cyan-100 text-cyan-600" },
                { icon: TrendingUp, title: "Performance Analytics", description: "Detailed insights into your progress and areas for improvement.", color: "bg-teal-100 text-teal-600" },
                { icon: Star, title: "High-Yield Practice Questions", description: "AAMC styled practice questions that mirror the real MCAT experience.", color: "bg-emerald-100 text-emerald-600" },
                { icon: BarChart, title: "Performance Insights", description: "Analyze your time spent and confidence levels across subjects, optimizing your study strategy.", color: "bg-sky-100 text-sky-600" }
              ].map((feature, index) => (
                <div key={index} className={`flex flex-col items-center text-center p-6 rounded-lg shadow-sm transition-all duration-300 hover:shadow-md hover:-translate-y-1 cursor-pointer ${feature.color}`}>
                  <feature.icon className="h-12 w-12 mb-4" />
                  <h3 className="text-xl font-bold mb-2 text-gray-900">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Score Calculator Section */}
        <section id="score-calculator" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight mb-8 text-center text-gray-900">MCAT Score Improvement Calculator</h2>
            <div className="max-w-3xl mx-auto bg-gradient-to-br from-blue-100 to-green-100 p-8 rounded-xl shadow-lg">
              <p className="text-center text-gray-600 mb-6">Adjust the sliders to see how UPangea can help improve your MCAT score.</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                {Object.entries(mcatScore).map(([section, score]) => (
                  <div key={section} className="space-y-2">
                    <Label htmlFor={section} className="text-gray-700">{section.toUpperCase()}</Label>
                    <Slider
                      id={section}
                      min={118}
                      max={132}
                      step={1}
                      value={[score]}
                      onValueChange={(value) => setMcatScore(prev => ({ ...prev, [section]: value[0] }))}
                      className="w-full"
                    />
                    <p className="text-center font-bold text-blue-600">{score}</p>
                  </div>
                ))}
              </div>
              <div className="text-center space-y-4">
                <div>
                  <h3 className="text-2xl font-bold text-blue-600">Current Total Score: {calculateTotalScore()}</h3>
                </div>
                <div>
                  <h3 className="text-2xl font-bold text-green-600">Potential Score with UPangea: {calculatePotentialImprovement()}</h3>
                  <p className="text-gray-600 mt-2">Our students see an average improvement of 10-15 points!</p>
                </div>
                <Button 
                  className="bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700 transition-all duration-300 transform hover:scale-105"
                  onClick={handleSignUp}
                >
                  Boost Your Score with UPangea
                </Button>
              </div>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section id="pricing" ref={pricingRef} className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-b from-blue-100 to-green-100">
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight mb-8 text-center text-gray-900">Simple, Transparent Pricing</h2>
            
            <div className="flex justify-center items-center space-x-4 mb-8">
              <span className={`text-lg ${!isAnnual ? 'font-semibold text-blue-600' : 'text-gray-600'}`}>Monthly</span>
              <div className="relative">
                <Switch
                  checked={isAnnual}
                  onCheckedChange={setIsAnnual}
                  className="data-[state=checked]:bg-blue-600"
                />
                <style jsx>{`
                  .relative :global(.sr-only),
                  .relative :global([role="switch"] span) {
                    display: none !important;
                  }
                `}</style>
              </div>
              <span className={`text-lg ${isAnnual ? 'font-semibold text-blue-600' : 'text-gray-600'}`}>Annual</span>
            </div>

            <div className="max-w-md mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="p-8">
                <h3 className="text-2xl font-bold text-center mb-4">UPangea Premium</h3>
                <div className="flex justify-center items-baseline mb-4">
                  <span className="text-5xl font-extrabold">${isAnnual ? '200' : '20'}</span>
                  <span className="text-xl text-gray-500 ml-1">/{isAnnual ? 'year' : 'month'}</span>
                </div>
                {isAnnual && (
                  <p className="text-center text-green-600 font-semibold mb-4">Save $40 with annual billing</p>
                )}
                <ul className="space-y-3 mb-6">
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    <span>AAMC MCAT replica interface</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    <span>3,000+ questions across all MCAT subjects</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    <span>Performance tracking</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    <span>Unlimited resets</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    <span>Rationales for all answer choices</span>
                  </li>
                </ul>
                <Button className="w-full bg-blue-600 text-white hover:bg-blue-700" onClick={handleSignUp}>
                  Get Started
                </Button>
              </div>
            </div>

            <p className="text-center mt-6 text-gray-600">
              All plans come with a 7-day free trial. No credit card required.
            </p>
          </div>
        </section>

        {/* Success Stories Section */}
        <section id="testimonials" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter md:text-4xl mb-8 text-center text-gray-900">Success Stories</h2>
            <div className="relative bg-gradient-to-br from-blue-100 to-green-100 p-8 rounded-xl shadow-lg max-w-3xl mx-auto">
              <div className="flex flex-col items-center text-center space-y-4">
                <p className="text-xl italic text-gray-700">"{testimonials[currentTestimonial].quote}"</p>
                <div>
                  <p className="font-semibold text-gray-900">{testimonials[currentTestimonial].name}</p>
                  <p className="text-blue-600 font-bold">MCAT Score: {testimonials[currentTestimonial].score}</p>
                </div>
              </div>
              <div className="flex justify-between mt-8">
                <Button
                  variant="outline"
                  onClick={() => setCurrentTestimonial((prev) => (prev === 0 ? testimonials.length - 1 : prev - 1))}
                  className="text-blue-600 border-blue-600 hover:bg-blue-50"
                >
                  Previous
                </Button>
                <Button
                  variant="outline"
                  onClick={() => setCurrentTestimonial((prev) => (prev === testimonials.length - 1 ? 0 : prev + 1))}
                  className="text-blue-600 border-blue-600 hover:bg-blue-50"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </section>

        {/* UPangea by the Numbers Section */}
        <section id="stats" className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-br from-blue-600 to-green-600">
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter md:text-4xl mb-12 text-center text-white">UPangea by the Numbers</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-center">
              {[
                { value: "1,000+", label: "Students Served" },
                { value: "3,000+", label: "Practice Questions" },
                { value: "514+", label: "Average User Score" },
                { value: "95%", label: "User Satisfaction" }
              ].map((stat, index) => (
                <div key={index} className="space-y-2 bg-white/10 p-6 rounded-lg backdrop-blur-sm transition-all duration-300 hover:bg-white/20 hover:scale-105">
                  <h3 className="text-4xl font-bold text-white">{stat.value}</h3>
                  <p className="text-blue-100">{stat.label}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section id="faq" className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-b from-blue-50 to-green-50">
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter md:text-4xl mb-8 text-center text-gray-900">Frequently Asked Questions</h2>
            <div className="max-w-3xl mx-auto space-y-4">
              {faqItems.map((item, index) => (
                <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300">
                  <button
                    className="flex justify-between items-center w-full p-4 text-left"
                    onClick={() => setOpenFAQ(openFAQ === index ? null : index)}
                  >
                    <span className="font-semibold text-gray-900">{item.question}</span>
                    {openFAQ === index ? <ChevronUp className="h-5 w-5 text-blue-600" /> : <ChevronDown className="h-5 w-5 text-blue-600" />}
                  </button>
                  <div className={`p-4 bg-blue-50 transition-all duration-300 ${openFAQ === index ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'} overflow-hidden`}>
                    <p className="text-gray-600">{item.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Signup Call-to-Action Section */}
        <section id="signup" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-gray-900">
                  Ready to Elevate Your MCAT Prep?
                </h2>
                <p className="mx-auto max-w-[600px] text-gray-600 md:text-xl lg:text-base xl:text-xl">
                  Join thousands of successful medical school applicants who trusted UPangea for their MCAT preparation.
                </p>
              </div>
              <div className="w-full max-w-sm space-y-2">
                <Button 
                  onClick={handleSignUp}
                  className="w-full bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700 transition-all duration-300 transform hover:scale-105"
                >
                  Start Your Free Trial
                </Button>
                <p className="text-xs text-gray-600">
                  By signing up, you agree to our{" "}
                  <Link to="#" className="underline underline-offset-2 hover:text-blue-600">
                    Terms & Conditions
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Footer Section */}
      <footer className="w-full py-6 bg-gray-900 text-gray-300">
        <div className="container px-4 md:px-6 mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {/* Product Links */}
            <div>
              <h3 className="font-semibold mb-2">Product</h3>
              <ul className="space-y-1">
                <li><Link to="#" className="hover:text-white transition-colors">Features</Link></li>
                <li><Link to="#" className="hover:text-white transition-colors">Pricing</Link></li>
                <li><Link to="#" className="hover:text-white transition-colors">FAQ</Link></li>
              </ul>
            </div>
            {/* Company Links */}
            <div>
              <h3 className="font-semibold mb-2">Company</h3>
              <ul className="space-y-1">
                <li><Link to="#" className="hover:text-white transition-colors">About Us</Link></li>
                <li><Link to="#" className="hover:text-white transition-colors">Careers</Link></li>
                <li><Link to="#" className="hover:text-white transition-colors">Contact</Link></li>
              </ul>
            </div>
            {/* Resources Links */}
            <div>
              <h3 className="font-semibold mb-2">Resources</h3>
              <ul className="space-y-1">
                <li><Link to="#" className="hover:text-white transition-colors">Blog</Link></li>
                <li><Link to="#" className="hover:text-white transition-colors">Study Tips</Link></li>
                <li><Link to="#" className="hover:text-white transition-colors">Success Stories</Link></li>
              </ul>
            </div>
            {/* Legal Links */}
            <div>
              <h3 className="font-semibold mb-2">Legal</h3>
              <ul className="space-y-1">
                <li><Link to="#" className="hover:text-white transition-colors">Privacy Policy</Link></li>
                <li><Link to="#" className="hover:text-white transition-colors">Terms of Service</Link></li>
                <li><Link to="#" className="hover:text-white transition-colors">Cookie Policy</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-800 flex flex-col sm:flex-row justify-between items-center">
            <p className="text-sm">© 2023 UPangea Inc. All rights reserved.</p>
            <div className="flex space-x-4 mt-4 sm:mt-0">
              {/* Social Media Icons */}
              <Link to="#" className="text-gray-300 hover:text-white transition-colors">
                <span className="sr-only">Facebook</span>
                {/* Include your Facebook SVG icon here */}
              </Link>
              <Link to="#" className="text-gray-300 hover:text-white transition-colors">
                <span className="sr-only">Instagram</span>
                {/* Include your Instagram SVG icon here */}
              </Link>
              <Link to="#" className="text-gray-300 hover:text-white transition-colors">
                <span className="sr-only">Twitter</span>
                {/* Include your Twitter SVG icon here */}
              </Link>
            </div>
          </div>
        </div>
      </footer>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-xl">
            <h3 className="text-2xl font-bold mb-4">Thank you for signing up!</h3>
            <p>We've sent a confirmation email to your inbox. Please check it to complete your registration.</p>
            <Button onClick={() => setShowModal(false)} className="mt-4 bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700">
              Close
            </Button>
          </div>
        </div>
      )}

      {/* Auth Modal */}
      {showAuthModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
            <h1 className="text-3xl font-bold text-center mb-6">
              {authMode === 'login' ? 'Log in to Upangea' : 'Sign up for UPangea'}
            </h1>
            <p className="text-center text-gray-600 mb-6">
              {authMode === 'login' 
                ? 'Access your MCAT prep materials and start mastering your exam'
                : 'Join thousands of successful medical school applicants'}
            </p>
            <Button 
              onClick={handleAuth} 
              className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-md transition duration-300"
            >
              {authMode === 'login' ? 'Log In' : 'Sign Up'} and Go to Dashboard
            </Button>
            <div className="mt-4 text-center text-sm text-gray-600">
              {authMode === 'login' 
                ? "Don't have an account? "
                : "Already have an account? "}
              <button 
                onClick={() => setAuthMode(authMode === 'login' ? 'signup' : 'login')} 
                className="text-blue-500 hover:underline"
              >
                {authMode === 'login' ? 'Sign up' : 'Log in'}
              </button>
            </div>
            <Button 
              onClick={() => setShowAuthModal(false)} 
              className="mt-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-2 rounded-md transition duration-300"
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
