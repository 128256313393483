import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '../components/ui/button';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardTitle, 
  CardDescription, 
  CardFooter 
} from '../components/ui/card';
import { Label } from '../components/ui/label';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogDescription } from '../components/ui/dialog';
import { Input } from '../components/ui/input';
import { 
  Lock, 
  Unlock, 
  Menu, 
  BookOpen, 
  ChevronDown, 
  ChevronRight, 
  Plus, 
  Minus, 
  AlertCircle, 
  BarChart2, 
  ClipboardList, 
  X, 
  Settings, 
  CreditCard, 
  User 
} from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useAuth0 } from '@auth0/auth0-react';
import logoImage from '../assets/images/5.svg';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import PremiumCreateTest from '../components/PremiumCreateTest';

const unavailableSubjects = [
  { name: "Behavioral Sciences", totalQuestions: 0, subCategories: [], isLocked: true, isChecked: false },
  { name: "Biochemistry", totalQuestions: 0, subCategories: [], isLocked: true, isChecked: false },
  { name: "Biology", totalQuestions: 0, subCategories: [], isLocked: true, isChecked: false },
  { name: "General Chemistry", totalQuestions: 0, subCategories: [], isLocked: true, isChecked: false },
  { name: "Organic Chemistry", totalQuestions: 0, subCategories: [], isLocked: true, isChecked: false },
  { name: "Physics", totalQuestions: 0, subCategories: [], isLocked: true, isChecked: false },
];

const ALL_SUBJECTS = [
  "CARS",
  "Biology",
  "Chemistry",
  "Physics",
  "Psychology",
  "Sociology",
  "Biochemistry"
];

function PremiumPopup({ isOpen, setIsOpen, currentPlan, togglePlan }) {
  const handleTogglePlan = () => {
    togglePlan();
    setIsOpen(false);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full relative">
        <button
          onClick={() => setIsOpen(false)}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <X className="h-6 w-6" />
        </button>
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4 text-blue-500">
            {currentPlan === 'Free' ? 'Upgrade to Premium' : 'Downgrade to Free'}
          </h2>
          <p className="text-lg mb-4 text-gray-600">
            {currentPlan === 'Free'
              ? 'Experience all premium features!'
              : 'Are you sure you want to downgrade?'}
          </p>
          <div className="bg-green-100 border-l-4 border-green-500 p-4 mb-4">
            <p className="font-bold text-green-700">
              {currentPlan === 'Free' ? 'Special Offer' : 'Current Plan'}
            </p>
            <p className="text-green-600">
              {currentPlan === 'Free'
                ? 'Upgrade now for full access!'
                : 'You currently have premium access.'}
            </p>
          </div>
          <button
            onClick={handleTogglePlan}
            className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
          >
            {currentPlan === 'Free' ? 'Upgrade to Premium' : 'Downgrade to Free'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default function Dashboard() {
  const { logout, user, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  const handleSignOut = () => {
    logout({ returnTo: window.location.origin });
    // The navigation to the landing page will happen automatically
    // after the logout is complete, thanks to Auth0's redirect
  };
  
  const handleSubjectCardClick = (subject) => {
    const isPremium = subject !== "CARS";
    const subjectData = performanceData.find(data => data.subject === subject) || {
      score: 0,
      total_questions_answered: 0,
      correct_answers: 0
    };

    if ((isPremium && currentPlan !== 'Premium') || subjectData.score === 0) {
      // Do nothing if the subject is premium and the user is not premium,
      // or if there is no performance data for the subject
      return;
    }
    
    setSelectedPerformanceSubject(subject);
    setIsPerformanceChartOpen(true);
  };

  const [subjects, setSubjects] = useState([]);
  const [passageTypes, setPassageTypes] = useState([
    { questions: 5, count: 0, available: 0 },
    { questions: 6, count: 0, available: 0 },
    { questions: 7, count: 0, available: 0 },
  ]);
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [activeTab, setActiveTab] = useState('create');
  const [isSubjectSelected, setIsSubjectSelected] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);
  const [previousTests, setPreviousTests] = useState([]);
  const [carsSubject, setCarsSubject] = useState(null);
  const [isQBankExpanded, setIsQBankExpanded] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isResetting, setIsResetting] = useState(false);
  const [isResetConfirmOpen, setIsResetConfirmOpen] = useState(false);
  const [isResetTypeConfirmOpen, setIsResetTypeConfirmOpen] = useState(false);
  const [resetConfirmText, setResetConfirmText] = useState('');
  const [isPremiumPopupOpen, setIsPremiumPopupOpen] = useState(false);
  const [currentPlan, setCurrentPlan] = useState('Free');

  // New state variables for performance chart
  const [selectedPerformanceSubject, setSelectedPerformanceSubject] = useState(null);
  const [isPerformanceChartOpen, setIsPerformanceChartOpen] = useState(false);

  const showErrorMessage = (message) => {
    setErrorMessage(message);
    setIsErrorModalOpen(true);
  };

  const togglePlan = async () => {
    if (!user) return;

    try {
      const newPlan = currentPlan === 'Free' ? 'Premium' : 'Free';

      // Get the Supabase user ID
      const { data: userData, error: userError } = await supabase
        .from('auth0_user_mapping')
        .select('id')
        .eq('auth0_user_id', user.sub)
        .single();

      if (userError) throw userError;

      const supabaseUserId = userData.id;

      // Update the user's plan in the database
      const { error: updateError } = await supabase
        .from('users')
        .update({ plan: newPlan })
        .eq('id', supabaseUserId);

      if (updateError) throw updateError;

      setCurrentPlan(newPlan);
      console.log(`Plan updated to ${newPlan}`);
    } catch (error) {
      console.error('Error updating plan:', error);
      showErrorMessage('Error updating plan');
    }
  };

  useEffect(() => {
    const fetchUserPlan = async () => {
      if (!user) return;

      try {
        // Get the Supabase user ID
        const { data: userData, error: userError } = await supabase
          .from('auth0_user_mapping')
          .select('id')
          .eq('auth0_user_id', user.sub)
          .single();

        if (userError) throw userError;

        const supabaseUserId = userData.id;

        // Fetch the user's current plan
        const { data: planData, error: planError } = await supabase
          .from('users')
          .select('plan')
          .eq('id', supabaseUserId)
          .single();

        if (planError) throw planError;

        setCurrentPlan(planData.plan || 'Free');
      } catch (error) {
        console.error('Error fetching user plan:', error);
        showErrorMessage('Error fetching user plan');
      }
    };

    fetchUserPlan();
  }, [user]);

  useEffect(() => {
    const initializeUser = async () => {
      if (isAuthenticated && user) {
        const auth0UserId = user.sub;
        
        // Check if user mapping exists
        const { data: mappingData, error: mappingError } = await supabase
          .from('auth0_user_mapping')
          .select('id')
          .eq('auth0_user_id', auth0UserId)
          .single();

        if (mappingError && mappingError.code !== 'PGRST116') {
          console.error('Error checking user mapping:', mappingError);
          return;
        }

        if (!mappingData) {
          // Create new user in 'users' table
          const { data: userData, error: userError } = await supabase
            .from('users')
            .insert({ email: user.email, plan: 'Free' })
            .select()
            .single();

          if (userError) {
            console.error('Error creating user:', userError);
            return;
          }

          // Create mapping in 'auth0_user_mapping' table
          const { error: mappingInsertError } = await supabase
            .from('auth0_user_mapping')
            .insert({ id: userData.id, auth0_user_id: auth0UserId });

          if (mappingInsertError) {
            console.error('Error creating user mapping:', mappingInsertError);
            return;
          }

          console.log('User initialized successfully');
        }
      }
    };

    initializeUser();
  }, [isAuthenticated, user]);

  useEffect(() => {
    const fetchSession = async () => {
      // Instead of a mock user ID, use the authenticated user's ID
      if (user) {
        // Fetch performance data
        const { data: performanceData, error: performanceError } = await supabase
          .from('performance')
          .select('id, user_id, subject, total_questions_answered, correct_answers, score')
          .eq('user_id', user.sub); // Use user.sub as the user ID from Auth0

        if (performanceError) {
          console.error('Performance data error:', performanceError);
        } else {
          console.log('Fetched performance data:', performanceData);
          setPerformanceData(performanceData || []);
        }

        // Fetch previous tests
        const { data: testsData, error: testsError } = await supabase
          .from('tests')
          .select('id, user_id, test_date, subject, total_questions, score')
          .eq('user_id', user.sub) // Use user.sub as the user ID from Auth0
          .order('test_date', { ascending: false });

        if (testsError) {
          console.error('Tests data error:', testsError);
        } else {
          console.log('Fetched tests data:', testsData);
          setPreviousTests(testsData || []);
        }
      }

      // Fetch the total number of CARS questions
      const { data: questionData, error: questionError } = await supabase
        .from('questions')
        .select('id')
        .order('id', { ascending: false })
        .limit(1);

      if (questionError) {
        console.error('Error fetching question count:', questionError);
      } else if (questionData && questionData.length > 0) {
        const totalQuestions = questionData[0].id;
        setCarsSubject({
          name: "CARS",
          totalQuestions: totalQuestions,
          subCategories: [
            { name: "Humanities", isChecked: false, questions: Math.floor(totalQuestions / 2) },
            { name: "Social Sciences", isChecked: false, questions: Math.ceil(totalQuestions / 2) },
          ],
          isLocked: false,
          isChecked: false
        });
      }

      // Fetch available passage types
      const { data: passageData, error: passageError } = await supabase
        .from('passages')
        .select('id, questions!inner(id)')
        .order('id');

      if (passageError) {
        console.error('Error fetching passage data:', passageError);
      } else {
        const availablePassageTypes = passageData.reduce((acc, passage) => {
          const questionCount = passage.questions.length;
          acc[questionCount] = (acc[questionCount] || 0) + 1;
          return acc;
        }, {});

        setPassageTypes([
          { questions: 5, count: 0, available: availablePassageTypes[5] || 0 },
          { questions: 6, count: 0, available: availablePassageTypes[6] || 0 },
          { questions: 7, count: 0, available: availablePassageTypes[7] || 0 },
        ]);
      }
    };

    fetchSession();
  }, [user]); // Add user to the dependency array

  useEffect(() => {
    if (carsSubject) {
      setSubjects([carsSubject]);
    }
  }, [carsSubject]);

  useEffect(() => {
    const totalCount = passageTypes.reduce((sum, type) => sum + type.count, 0);
    if (totalCount > 0) {
      setSubjects(prevSubjects => 
        prevSubjects.map(subject => ({
          ...subject,
          isChecked: true,
          subCategories: subject.subCategories.map(subCategory => ({
            ...subCategory,
            isChecked: true
          }))
        }))
      );
    }
  }, [passageTypes]);

  useEffect(() => {
    const hasSelectedSubject = subjects.some(subject => 
      subject.isChecked || subject.subCategories.some(subCategory => subCategory.isChecked)
    );
    setIsSubjectSelected(hasSelectedSubject);
  }, [subjects]);

  const fetchUserData = async () => {
    if (!user) return;

    try {
      // Get the Supabase user ID
      const { data: mappingData, error: mappingError } = await supabase
        .from('auth0_user_mapping')
        .select('id')
        .eq('auth0_user_id', user.sub)
        .single();

      if (mappingError) throw mappingError;

      const supabaseUserId = mappingData.id;

      // Fetch performance data
      const { data: performanceData, error: performanceError } = await supabase
        .from('performance')
        .select('*')
        .eq('user_id', supabaseUserId);

      if (performanceError) throw performanceError;

      setPerformanceData(performanceData);

      // Fetch previous tests
      const { data: testsData, error: testsError } = await supabase
        .from('tests')
        .select('id, user_id, test_date, subject, passage_ids, score')
        .eq('user_id', supabaseUserId)
        .order('test_date', { ascending: false });

      if (testsError) throw testsError;

      // Fetch question counts for all passages
      const allPassageIds = testsData.flatMap(test => test.passage_ids || []);
      const { data: passagesData, error: passagesError } = await supabase
        .from('passages')
        .select('id, question_count')
        .in('id', allPassageIds);

      if (passagesError) throw passagesError;

      // Create a map of passage id to question count
      const passageQuestionCounts = Object.fromEntries(
        passagesData.map(passage => [passage.id, passage.question_count])
      );

      // Calculate total questions for each test
      const testsWithQuestionCounts = testsData.map(test => ({
        ...test,
        total_questions: (test.passage_ids || []).reduce(
          (sum, passageId) => sum + (passageQuestionCounts[passageId] || 0),
          0
        )
      }));

      setPreviousTests(testsWithQuestionCounts);

      // Get the list of completed passage IDs
      const completedPassageIds = testsData.flatMap(test => test.passage_ids || []);

      // Fetch available passages (excluding completed ones)
      const { data: availablePassages, error: availablePassagesError } = await supabase
        .from('passages')
        .select('id, subject, question_count')
        .not('id', 'in', `(${completedPassageIds.join(',')})`)
        .order('id');

      if (availablePassagesError) throw availablePassagesError;

      // Update available passage types
      const availablePassageTypes = availablePassages.reduce((acc, passage) => {
        const key = passage.question_count;
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});

      setPassageTypes(prevTypes => 
        prevTypes.map(type => ({
          ...type,
          available: availablePassageTypes[type.questions] || 0
        }))
      );

      // Calculate available questions for CARS and its subcategories
      const availableQuestionsCount = {
        CARS: 0,
        Humanities: 0,
        "Social Sciences": 0
      };

      availablePassages.forEach(passage => {
        const questionCount = passage.question_count;
        availableQuestionsCount.CARS += questionCount;
        // Assuming an even split between Humanities and Social Sciences
        // You may need to adjust this based on your actual data
        availableQuestionsCount.Humanities += Math.floor(questionCount / 2);
        availableQuestionsCount["Social Sciences"] += Math.ceil(questionCount / 2);
      });

      setSubjects(prevSubjects => 
        prevSubjects.map(subject => ({
          ...subject,
          totalQuestions: availableQuestionsCount[subject.name] || 0,
          subCategories: subject.subCategories.map(subCategory => ({
            ...subCategory,
            questions: availableQuestionsCount[subCategory.name] || 0
          }))
        }))
      );

    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [user]);

  const toggleSubject = (subjectName) => {
    setSubjects(prevSubjects => {
      return prevSubjects.map(subject => {
        if (subject.name === subjectName) {
          const newIsChecked = !subject.isChecked;
          return {
            ...subject,
            isChecked: newIsChecked,
            subCategories: subject.subCategories.map(subCategory => ({
              ...subCategory,
              isChecked: newIsChecked
            }))
          };
        }
        return subject;
      });
    });
  };

  const toggleSubCategory = (subjectName, subCategoryName) => {
    setSubjects(prevSubjects => {
      return prevSubjects.map(subject => {
        if (subject.name === subjectName) {
          const updatedSubCategories = subject.subCategories.map(subCategory =>
            subCategory.name === subCategoryName
              ? { ...subCategory, isChecked: !subCategory.isChecked }
              : subCategory
          );
          return {
            ...subject,
            isChecked: updatedSubCategories.some(subCategory => subCategory.isChecked),
            subCategories: updatedSubCategories
          };
        }
        return subject;
      });
    });
  };

  const updatePassageTypeCount = (questions, increment) => {
    setPassageTypes(prevTypes => 
      prevTypes.map(type => 
        type.questions === questions
          ? { 
              ...type, 
              count: increment 
                ? Math.min(type.count + 1, type.available) 
                : Math.max(0, type.count - 1) 
            }
          : type
      )
    );
  };

  const handleResetConfirm = () => {
    setIsResetConfirmOpen(false);
    setIsResetTypeConfirmOpen(true);
  };

  const handleResetTypeConfirm = () => {
    if (resetConfirmText.toUpperCase() === 'RESET') {
      setIsResetTypeConfirmOpen(false);
      handleResetData();
    }
  };

  const handleResetData = async () => {
    try {
      setIsResetting(true);
  
      // Get the Supabase user ID from the auth0_user_mapping table
      const { data: mappingData, error: mappingError } = await supabase
        .from('auth0_user_mapping')
        .select('id')
        .eq('auth0_user_id', user.sub)
        .single();
  
      if (mappingError) throw mappingError;
  
      const supabaseUserId = mappingData.id;
  
      // Delete entries in 'tests' table
      const { error: deleteTestsError } = await supabase
        .from('tests')
        .delete()
        .eq('user_id', supabaseUserId);
  
      if (deleteTestsError) throw deleteTestsError;
  
      // Delete entries in 'performance' table
      const { error: deletePerformanceError } = await supabase
        .from('performance')
        .delete()
        .eq('user_id', supabaseUserId);
  
      if (deletePerformanceError) throw deletePerformanceError;
  
      // Delete entries in 'test_progress' table
      const { error: deleteTestProgressError } = await supabase
        .from('test_progress')
        .delete()
        .eq('user_id', supabaseUserId);
  
      if (deleteTestProgressError) throw deleteTestProgressError;
  
      // Reset local state
      setPreviousTests([]);
      setPerformanceData([]);
  
      // Fetch updated user data
      await fetchUserData();
  
      // Show success message
      console.log("Data Reset", "Your data has been reset successfully.");
    } catch (error) {
      console.error('Error resetting data:', error);
      console.error("Error", "An error occurred while resetting your data.");
    } finally {
      setIsResetting(false);
    }
  };
  
  const totalQuestions = passageTypes.reduce((sum, type) => sum + type.questions * type.count, 0);

  const renderSubjects = (subjectsToRender) => (
    subjectsToRender.map(subject => (
      <Card 
        key={subject.name} 
        className="mb-4" // Removed 'cursor-pointer' and onClick
      >
        <CardHeader className="pb-2">
          <CardTitle className="text-lg">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input 
                  type="checkbox" 
                  className="mr-3 ml-1" 
                  checked={subject.isChecked}
                  onChange={() => toggleSubject(subject.name)}
                  disabled={subject.totalQuestions === 0}
                />
                <span>{subject.name}</span>
              </div>
              <div className="flex items-center">
                <span className="text-sm text-gray-500 mr-3">{subject.totalQuestions}</span>
                {subject.isLocked ? (
                  <Lock className="w-4 h-4 text-gray-500 mr-1" />
                ) : (
                  <Unlock className="w-4 h-4 text-teal-500 mr-1" />
                )}
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          {subject.subCategories.map(subCategory => (
            <div key={subCategory.name} className="flex items-center justify-between ml-4 mt-1">
              <div className="flex items-center">
                <input 
                  type="checkbox" 
                  className="mr-2" 
                  checked={subCategory.isChecked}
                  onChange={() => toggleSubCategory(subject.name, subCategory.name)}
                  disabled={subCategory.questions === 0}
                />
                <span>{subCategory.name}</span>
              </div>
              <span className="text-sm text-gray-500">({subCategory.questions})</span>
            </div>
          ))}
        </CardContent>
      </Card>
    ))
  );

  const handleCreateTest = async (testData) => {
    if (!user) {
      setIsLoginDialogOpen(true);
      return;
    }

    try {
      // Get the Supabase user ID
      const { data: mappingData, error: mappingError } = await supabase
        .from('auth0_user_mapping')
        .select('id')
        .eq('auth0_user_id', user.sub)
        .single();

      if (mappingError) throw mappingError;

      const supabaseUserId = mappingData.id;

      // Create the test using the provided testData
      const { data, error } = await supabase
        .from('tests')
        .insert({
          user_id: supabaseUserId,
          subject: testData.selectedTopics.join(', '),
          total_questions: testData.questionCount,
          test_date: new Date().toISOString(),
          // Add other necessary fields
        })
        .select()
        .single();

      if (error) throw error;
      console.log('Test created successfully:', data);

      // Redirect to test interface with the new test ID
      navigate(`/test/${data.id}`);
    } catch (error) {
      console.error('Error creating test:', error.message);
      showErrorMessage('Error creating test');
    }
  };

  const handleCreateTestClick = () => {
    setActiveTab('create');
  };

  const toggleQBank = () => {
    setIsQBankExpanded(!isQBankExpanded);
  };

  const hasAvailableQuestions = useCallback(() => {
    return subjects.some(subject => subject.totalQuestions > 0);
  }, [subjects]);

  // Function to get chart data for a subject
  const getChartData = (subjectName) => {
    // Filter previousTests for the selected subject
    const filteredTests = previousTests
      .filter(test => test.subject === subjectName)
      .sort((a, b) => new Date(a.test_date) - new Date(b.test_date));

    // Map to chart data format
    return filteredTests.map(test => ({
      date: new Date(test.test_date).toLocaleDateString(),
      score: test.score,
    }));
  };

  return (
    <div className="flex h-screen bg-gray-50">
      {isSidebarVisible && (
        <div className="w-64 bg-gradient-to-b from-blue-600 to-teal-500 text-white p-4 flex flex-col">
          <div className="flex flex-col items-center mb-6">
            <img src={logoImage} alt="UPangea Logo" className="h-20 w-20 mb-1" />
            <div className="text-center">
              <h1 className="text-4xl font-bold leading-none">UPangea</h1>
              <h2 className="text-xl text-white mt-2">MCAT</h2>
            </div>
          </div>
          <nav className="flex-grow">
            <h3 className="text-lg font-semibold mb-4 text-white">Dashboard</h3>
            <ul className="space-y-2">
              <li className="font-bold flex items-center mb-2 text-white cursor-pointer" onClick={toggleQBank}>
                {isQBankExpanded ? <ChevronDown className="w-5 h-5 mr-2" /> : <ChevronRight className="w-5 h-5 mr-2" />}
                <BookOpen className="w-5 h-5 mr-2" />
                QBank
              </li>
              {isQBankExpanded && (
                <>
                  <li>
                    <button
                      onClick={() => setActiveTab('create')}
                      className={`w-full text-left py-2 px-4 rounded transition-colors duration-200 ${
                        activeTab === 'create'
                          ? 'bg-white text-blue-600 font-semibold'
                          : 'text-white hover:bg-white hover:bg-opacity-20'
                      }`}
                    >
                      <Plus className="w-4 h-4 inline-block mr-2" />
                      Create Test
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setActiveTab('previous')}
                      className={`w-full text-left py-2 px-4 rounded transition-colors duration-200 ${
                        activeTab === 'previous'
                          ? 'bg-white text-blue-600 font-semibold'
                          : 'text-white hover:bg-white hover:bg-opacity-20'
                      }`}
                    >
                      <ClipboardList className="w-4 h-4 inline-block mr-2" />
                      Previous Tests
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setActiveTab('performance')}
                      className={`w-full text-left py-2 px-4 rounded transition-colors duration-200 ${
                        activeTab === 'performance'
                          ? 'bg-white text-blue-600 font-semibold'
                          : 'text-white hover:bg-white hover:bg-opacity-20'
                      }`}
                    >
                      <BarChart2 className="w-4 h-4 inline-block mr-2" />
                      Performance
                    </button>
                  </li>
                </>
              )}
            </ul>
          </nav>
          <div className="mt-auto">
            <Button
              variant="outline"
              className="w-full text-white border-white hover:bg-white hover:bg-opacity-20"
              onClick={() => setIsResetConfirmOpen(true)}
              disabled={isResetting}
            >
              {isResetting ? 'Resetting...' : 'Reset All Progress'}
            </Button>
          </div>
        </div>
      )}

      <div className="flex-1 flex flex-col">
        <header className="bg-white shadow-sm border-b border-gray-200">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <div className="flex items-center">
              <Button variant="ghost" size="icon" onClick={() => setIsSidebarVisible(!isSidebarVisible)} className="mr-2">
                <Menu className="h-6 w-6" />
              </Button>
              <h2 className="text-xl font-semibold text-gray-700">
                {activeTab === 'create' ? 'Create Test' : activeTab === 'previous' ? 'Previous Tests' : 'Performance'}
              </h2>
            </div>
            <div className="flex items-center space-x-4">
              {user && (
                <span className="text-sm font-medium text-gray-700">Welcome, {user.name}</span>
              )}
              <Button variant="outline" onClick={() => setIsPremiumPopupOpen(true)}>
                Upgrade to Premium
              </Button>
              <Button onClick={handleSignOut} variant="outline" className="text-red-600 border-red-600 hover:bg-red-50">
                Log Out
              </Button>
            </div>
          </div>
        </header>

        <main className="flex-1 overflow-y-auto p-8">
          <div className="bg-white rounded-lg shadow-md p-6 max-w-4xl mx-auto">
            {activeTab === 'create' && (
              <>
                {currentPlan === 'Premium' ? (
                  <PremiumCreateTest onCreateTest={handleCreateTest} />
                ) : (
                  <>
                    <div className="bg-teal-50 border-l-4 border-teal-500 text-teal-700 p-4 mb-6" role="alert">
                      <div className="flex">
                        <div className="py-1"><AlertCircle className="h-6 w-6 text-teal-500 mr-4" /></div>
                        <div>
                          <p className="font-bold">Available Subjects</p>
                          <p className="text-sm">Currently, only Critical Analysis & Reasoning Skills (CARS) is available for free users. Upgrade to access all subjects and features!</p>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                      <div>
                        <h3 className="text-lg font-semibold mb-4 text-gray-700">Available Subjects</h3>
                        {renderSubjects(subjects)}
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold mb-4 text-gray-700">Premium Subjects</h3>
                        <Card className="bg-gray-50 border border-gray-200">
                          <CardContent className="pt-6">
                            <ul className="space-y-2">
                              {unavailableSubjects.map(subject => (
                                <li key={subject.name} className="flex items-center text-gray-500">
                                  <Lock className="w-4 h-4 mr-2 text-teal-500" />
                                  {subject.name}
                                </li>
                              ))}
                            </ul>
                          </CardContent>
                        </Card>
                        <Button className="mt-4 w-full bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white transition-colors duration-200" onClick={() => setIsPremiumPopupOpen(true)}>
                          Upgrade to Premium
                        </Button>
                      </div>
                    </div>

                    <hr className="my-6" />

                    <div className={`mb-6 transition-all duration-300 ${isSubjectSelected ? '' : 'opacity-50 pointer-events-none'}`}>
                      <h3 className="text-lg font-semibold mb-2 text-gray-700">Build Your Test</h3>
                      <p className="text-sm text-gray-600 mb-4">
                        {isSubjectSelected 
                          ? "Select the number of passages for each available question type:" 
                          : "Please select a subject above to start building your test."}
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        {passageTypes.map((type) => (
                          <Card 
                            key={type.questions} 
                            className={`flex items-center justify-between p-4 border border-gray-200 transition-colors duration-200 ${
                              type.available > 0 ? 'hover:border-teal-500' : 'opacity-50 cursor-not-allowed'
                            }`}
                          >
                            <div>
                              <h4 className="font-semibold text-gray-700">{type.questions}-Question Passage</h4>
                              <p className="text-sm text-gray-600">Total: {type.questions * type.count} questions</p>
                              <p className="text-xs text-gray-500">Available: {type.available}</p>
                            </div>
                            <div className="flex items-center">
                              <Button
                                size="icon"
                                variant="outline"
                                onClick={() => updatePassageTypeCount(type.questions, false)}
                                disabled={type.count === 0 || type.available === 0}
                                className="text-teal-500 hover:text-teal-600 hover:border-teal-500"
                              >
                                <Minus className="h-4 w-4" />
                              </Button>
                              <span className="mx-2 font-semibold text-gray-700">{type.count}</span>
                              <Button
                                size="icon"
                                variant="outline"
                                onClick={() => updatePassageTypeCount(type.questions, true)}
                                disabled={type.count >= type.available}
                                className="text-teal-500 hover:text-teal-600 hover:border-teal-500"
                              >
                                <Plus className="h-4 w-4" />
                              </Button>
                            </div>
                          </Card>
                        ))}
                      </div>
                      <p className="mt-4 text-right font-semibold text-gray-700">Total Questions: {totalQuestions}</p>
                    </div>

                    <Button 
                      className="mt-6 bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white w-full transition-all duration-300" 
                      onClick={handleCreateTest}
                      disabled={!isSubjectSelected || totalQuestions === 0 || !hasAvailableQuestions()}
                    >
                      {!isSubjectSelected 
                        ? "Select a subject to create a test" 
                        : !hasAvailableQuestions()
                          ? "No questions available"
                          : totalQuestions === 0 
                            ? "Add questions to create a test" 
                            : `Create Test (${totalQuestions} questions)`}
                    </Button>

                    {!hasAvailableQuestions() && (
                      <div className="mt-4 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
                        <p className="font-bold">No questions available</p>
                        <p>You have completed all available questions. Please check back later for new content.</p>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {activeTab === 'previous' && (
              <div>
                <h3 className="text-lg font-semibold mb-4 text-gray-700">Previous Tests</h3>
                {console.log('Rendering previous tests, length:', previousTests.length)}
                {previousTests.length > 0 ? (
                  <ul className="space-y-4">
                    {previousTests.map((test) => (
                      <li key={test.id} className="flex items-center justify-between p-4 border border-gray-200 rounded-md">
                        <div>
                          <h4 className="font-semibold text-gray-700">{test.subject || `Test ${test.id}`}</h4>
                          <p className="text-sm text-gray-600">Taken on {new Date(test.test_date).toLocaleDateString()}</p>
                        </div>
                        <div>
                          <p className="font-semibold text-teal-600">{test.score !== undefined ? `${test.score}%` : 'N/A'}</p>
                          <p className="text-sm text-gray-600">{test.total_questions || 'N/A'} questions</p>
                        </div>
                        <div>
                          <Button
                            onClick={() => navigate(`/test/${test.id}/review`)}
                            className="mt-2 bg-blue-500 hover:bg-blue-600 text-white"
                          >
                            Review Test
                          </Button>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Card className="p-6 text-center">
                    <p className="text-gray-600">You haven't taken any tests yet. Start by creating your first test!</p>
                    <Button 
                      className="mt-4 bg-blue-500 hover:bg-blue-600 text-white"
                      onClick={handleCreateTestClick}
                    >
                      Create a Test
                    </Button>
                  </Card>
                )}
              </div>
            )}

            {activeTab === 'performance' && (
              <div>
                <h3 className="text-lg font-semibold mb-6 text-gray-700">Performance Overview</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {ALL_SUBJECTS.map(subject => {
                    const isPremium = subject !== "CARS";
                    const subjectData = performanceData.find(data => data.subject === subject) || {
                      score: 0,
                      total_questions_answered: 0,
                      correct_answers: 0
                    };

                    const correctAnswers = subjectData.correct_answers;
                    const incorrectAnswers = subjectData.total_questions_answered - subjectData.correct_answers;

                    return (
                      <Card 
                        key={subject} 
                        className={`p-6 hover:shadow-lg transition-shadow duration-300 ${
                          (!isPremium || currentPlan === 'Premium') && subjectData.score > 0 ? 'cursor-pointer' : 'cursor-default'
                        }`}
                        onClick={() => handleSubjectCardClick(subject)}
                      >
                        <div className="flex items-center justify-between mb-4">
                          <h4 className="text-xl font-semibold text-gray-800">{subject}</h4>
                          <div className="w-20 h-20">
                            <CircularProgressbar
                              value={isPremium && currentPlan !== 'Premium' ? 0 : subjectData.score}
                              text={`${isPremium && currentPlan !== 'Premium' ? '--' : subjectData.score}%`}
                              styles={buildStyles({
                                textSize: '22px',
                                pathColor: `rgba(62, 152, 199, ${(isPremium && currentPlan !== 'Premium' ? 0 : subjectData.score) / 100})`,
                                textColor: '#3e98c7',
                                trailColor: '#d6d6d6',
                              })}
                            />
                          </div>
                        </div>
                        <div className="space-y-2">
                          <p className="text-sm text-gray-600">
                            Questions Answered: <span className="font-medium">{isPremium && currentPlan !== 'Premium' ? '--' : subjectData.total_questions_answered}</span>
                          </p>
                          <div className="flex items-center space-x-2">
                            <div className="flex-1 bg-gray-200 rounded-full h-2 overflow-hidden">
                              <div 
                                className="h-full bg-green-500" 
                                style={{ width: `${isPremium && currentPlan !== 'Premium' || subjectData.total_questions_answered === 0 ? 0 : (correctAnswers / subjectData.total_questions_answered * 100)}%` }}
                              ></div>
                            </div>
                            <span className="text-sm font-medium text-green-600">{isPremium && currentPlan !== 'Premium' ? '--' : correctAnswers}</span>
                            <span className="text-sm font-medium text-red-600">{isPremium && currentPlan !== 'Premium' ? '--' : incorrectAnswers}</span>
                          </div>
                        </div>
                        {isPremium && currentPlan !== 'Premium' && (
                          <div className="mt-4 p-2 bg-yellow-100 rounded-md">
                            <p className="text-sm text-yellow-700">Upgrade to Premium for access to {subject} content!</p>
                          </div>
                        )}
                      </Card>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </main>
      </div>

      {/* Performance Chart Dialog */}
      <Dialog open={isPerformanceChartOpen} onOpenChange={setIsPerformanceChartOpen}>
        <DialogContent className="sm:max-w-3xl bg-white">
          <DialogHeader>
            <DialogTitle className="text-gray-900">{selectedPerformanceSubject} Performance Over Time</DialogTitle>
            <DialogDescription className="text-gray-600">
              View your percentage scores over time for the {selectedPerformanceSubject} subject.
            </DialogDescription>
          </DialogHeader>
          <div className="w-full h-64">
            {selectedPerformanceSubject ? (
              getChartData(selectedPerformanceSubject).length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={getChartData(selectedPerformanceSubject)}
                    margin={{
                      top: 5, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" stroke="#4B5563" />
                    <YAxis domain={[0, 100]} stroke="#4B5563" />
                    <Tooltip contentStyle={{ backgroundColor: 'white', border: '1px solid #E5E7EB' }} />
                    <Line 
                      type="monotone" 
                      dataKey="score" 
                      stroke="#3e98c7" 
                      strokeWidth={2}
                      dot={{ r: 4, fill: "#3e98c7" }}
                      activeDot={{ r: 8 }}
                      connectNulls
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p className="text-center text-gray-600">No test data available for {selectedPerformanceSubject}.</p>
              )
            ) : (
              <p className="text-center text-gray-600">No data available.</p>
            )}
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsPerformanceChartOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Login Dialog */}
      <Dialog open={isLoginDialogOpen} onOpenChange={setIsLoginDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Log in or Sign up</DialogTitle>
            <DialogDescription>
              Access your UPangea account or create a new one.
            </DialogDescription>
          </DialogHeader>
          <div className="grid w-full grid-cols-2">
            <button
              onClick={() => setActiveTab('login')}
              className={`w-full text-left py-2 px-4 rounded transition-colors duration-200 ${
                activeTab === 'login'
                  ? 'bg-white bg-opacity-20 text-white'
                  : 'text-gray-200 hover:bg-white hover:bg-opacity-10'
              }`}
            >
              Login
            </button>
            <button
              onClick={() => setActiveTab('register')}
              className={`w-full text-left py-2 px-4 rounded transition-colors duration-200 ${
                activeTab === 'register'
                  ? 'bg-white bg-opacity-20 text-white'
                  : 'text-gray-200 hover:bg-white hover:bg-opacity-10'
              }`}
            >
              Register
            </button>
          </div>
          {activeTab === 'login' && (
            <Card>
              <CardHeader>
                <CardTitle>Login</CardTitle>
                <CardDescription>
                  Enter your email and password to access your account.
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-2">
                <div className="space-y-1">
                  <Label htmlFor="email">Email</Label>
                  <Input id="email" type="email" placeholder="m@example.com" />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="password">Password</Label>
                  <Input id="password" type="password" />
                </div>
              </CardContent>
              <CardFooter>
                <Button className="w-full bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white">Log in</Button>
              </CardFooter>
            </Card>
          )}
          {activeTab === 'register' && (
            <Card>
              <CardHeader>
                <CardTitle>Create an account</CardTitle>
                <CardDescription>
                  Enter your details to create a new account.
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-2">
                <div className="space-y-1">
                  <Label htmlFor="new-email">Email</Label>
                  <Input id="new-email" type="email" placeholder="m@example.com" />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="new-password">Password</Label>
                  <Input id="new-password" type="password" />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="confirm-password">Confirm Password</Label>
                  <Input id="confirm-password" type="password" />
                </div>
              </CardContent>
              <CardFooter>
                <Button className="w-full bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white">Sign Up</Button>
              </CardFooter>
            </Card>
          )}
        </DialogContent>
      </Dialog>

      {/* Profile Dialog */}
      <Dialog open={isProfileOpen} onOpenChange={setIsProfileOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Profile</DialogTitle>
            <DialogDescription>
              View and manage your UPangea profile and subscription.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            {user ? (
              <>
                <div className="flex items-center gap-4">
                  <div className="h-16 w-16 bg-gray-200 rounded-full flex items-center justify-center">
                    <User className="w-8 h-8 text-gray-500" />
                  </div>
                  <div>
                    <h3 className="font-medium">{user.email}</h3>
                    <p className="text-sm text-gray-500">{user.email}</p>
                  </div>
                </div>
                <hr />
                <div>
                  <h4 className="font-medium mb-2">Current Plan</h4>
                  <p className="text-sm text-gray-500">Free</p>
                  <Button className="mt-2 bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white">Upgrade to Premium</Button>
                </div>
                <hr />
                <div>
                  <h4 className="font-medium mb-2">Account Settings</h4>
                  <Button variant="outline" className="w-full justify-start">
                    <Settings className="mr-2 h-4 w-4" />
                    Edit Profile
                  </Button>
                  <Button variant="outline" className="w-full justify-start mt-2">
                    <CreditCard className="mr-2 h-4 w-4" />
                    Manage Subscription
                  </Button>
                </div>
              </>
            ) : (
              <p>Please log in to view your profile.</p>
            )}
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsProfileOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Error Modal */}
      <Dialog open={isErrorModalOpen} onOpenChange={setIsErrorModalOpen}>
        <DialogContent className="bg-white">
          <DialogHeader>
            <DialogTitle>Error</DialogTitle>
          </DialogHeader>
          <p>{errorMessage}</p>
          <DialogFooter>
            <Button onClick={() => setIsErrorModalOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Initial Reset Confirmation Dialog */}
      <Dialog open={isResetConfirmOpen} onOpenChange={setIsResetConfirmOpen}>
        <DialogContent className="bg-white">
          <DialogHeader>
            <DialogTitle>Confirm Reset</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            Are you sure you want to reset all your progress? This action cannot be undone.
          </DialogDescription>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsResetConfirmOpen(false)}>Cancel</Button>
            <Button onClick={handleResetConfirm}>Confirm</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Type Confirmation Dialog */}
      <Dialog open={isResetTypeConfirmOpen} onOpenChange={setIsResetTypeConfirmOpen}>
        <DialogContent className="bg-white">
          <DialogHeader>
            <DialogTitle>Final Confirmation</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            To reset all progress, please type "RESET" in the box below:
          </DialogDescription>
          <Input
            value={resetConfirmText}
            onChange={(e) => setResetConfirmText(e.target.value)}
            placeholder="Type RESET here"
          />
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsResetTypeConfirmOpen(false)}>Cancel</Button>
            <Button onClick={handleResetTypeConfirm} disabled={resetConfirmText.toUpperCase() !== 'RESET'}>
              Reset All Progress
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <PremiumPopup 
        isOpen={isPremiumPopupOpen} 
        setIsOpen={setIsPremiumPopupOpen} 
        currentPlan={currentPlan}
        togglePlan={togglePlan}
      />
    </div>
  );
}
