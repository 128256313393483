import React, { useState } from 'react';
import { Button } from './ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/card';
import { Checkbox } from './ui/checkbox';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Slider } from './ui/slider';
import { Separator } from './ui/separator';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from './ui/collapsible';
import { ScrollArea } from './ui/scroll-area';
import { 
  Brain, 
  Dna, 
  Microscope, 
  BookOpen, 
  Beaker, 
  AtomIcon, 
  Zap, 
  ChevronDown, 
  User 
} from 'lucide-react';

const subjects = [
  {
    name: "Behavioral Sciences",
    icon: <Brain className="w-4 h-4" />,
    totalQuestions: 301,
    topics: [
      { name: "Sensation, Perception, and Consciousness", questions: 50 },
      { name: "Learning, Memory, and Cognition", questions: 79 },
      { name: "Motivation, Emotion, Attitudes, Personality, and Stress", questions: 33 },
      { name: "Identity and Social Interaction", questions: 78 },
      { name: "Demographics and Social Structure", questions: 61 },
    ]
  },
  {
    name: "Biochemistry",
    icon: <Dna className="w-4 h-4" />,
    totalQuestions: 532,
    topics: [
      { name: "Amino Acids and Proteins", questions: 187 },
      { name: "Enzymes", questions: 114 },
      { name: "Carbohydrates, Nucleotides, and Lipids", questions: 90 },
      { name: "Metabolic Reactions", questions: 141 },
    ]
  },
  {
    name: "Biology",
    icon: <Microscope className="w-4 h-4" />,
    totalQuestions: 520,
    topics: [
      { name: "Molecular Biology", questions: 71 },
      { name: "Cellular Biology", questions: 63 },
      { name: "Genetics and Evolution", questions: 62 },
      { name: "Reproduction", questions: 50 },
      { name: "Endocrine and Nervous Systems", questions: 83 },
      { name: "Circulation and Respiration", questions: 49 },
      { name: "Digestion and Excretion", questions: 57 },
      { name: "Musculoskeletal System", questions: 44 },
      { name: "Skin and Immune Systems", questions: 41 },
    ]
  },
  {
    name: "Critical Analysis & Reasoning Skills",
    icon: <BookOpen className="w-4 h-4" />,
    totalQuestions: 459,
    topics: [
      { name: "Humanities", questions: 199 },
      { name: "Social Sciences", questions: 260 },
    ]
  },
  {
    name: "General Chemistry",
    icon: <AtomIcon className="w-4 h-4" />,
    totalQuestions: 478,
    topics: [
      { name: "Atomic Theory and Chemical Composition", questions: 118 },
      { name: "Interactions of Chemical Substances", questions: 99 },
      { name: "Thermodynamics, Kinetics & Gas Laws", questions: 126 },
      { name: "Solutions and Electrochemistry", questions: 135 },
    ]
  },
  {
    name: "Organic Chemistry",
    icon: <Beaker className="w-4 h-4" />,
    totalQuestions: 315,
    topics: [
      { name: "Introduction to Organic Chemistry", questions: 79 },
      { name: "Functional Groups and Their Reactions", questions: 158 },
      { name: "Separation Techniques, Spectroscopy, and Analytical Methods", questions: 78 },
    ]
  },
  {
    name: "Physics",
    icon: <Zap className="w-4 h-4" />,
    totalQuestions: 394,
    topics: [
      { name: "Mechanics and Energy", questions: 99 },
      { name: "Fluids", questions: 91 },
      { name: "Electrostatics and Circuits", questions: 82 },
      { name: "Light and Sound", questions: 106 },
      { name: "Thermodynamics", questions: 16 },
    ]
  },
  // Add other subjects here...
];

export default function PremiumCreateTest({ onCreateTest }) {
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [questionCount, setQuestionCount] = useState(59);

  const handleTopicToggle = (topicName) => {
    setSelectedTopics(prev => 
      prev.includes(topicName) 
        ? prev.filter(t => t !== topicName)
        : [...prev, topicName]
    );
  };

  const handleCreateTest = () => {
    onCreateTest({ selectedTopics, questionCount });
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Create Your Custom MCAT Test</CardTitle>
        <CardDescription>Select subjects and topics, then set the number of questions for your test.</CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div>
          <h3 className="text-lg font-semibold mb-2">Subjects and Topics</h3>
          <ScrollArea className="h-[400px] rounded-md border p-4">
            {subjects.map((subject) => (
              <Collapsible key={subject.name} className="mb-4">
                <CollapsibleTrigger className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    {subject.icon}
                    <span className="ml-2 font-medium">{subject.name}</span>
                    <span className="ml-2 text-sm text-gray-500">({subject.totalQuestions})</span>
                  </div>
                  <ChevronDown className="h-4 w-4" />
                </CollapsibleTrigger>
                <CollapsibleContent className="mt-2">
                  {subject.topics.map((topic) => (
                    <div key={topic.name} className="flex items-center space-x-2 ml-6 mt-2">
                      <Checkbox
                        id={topic.name}
                        checked={selectedTopics.includes(topic.name)}
                        onCheckedChange={() => handleTopicToggle(topic.name)}
                      />
                      <Label htmlFor={topic.name} className="text-sm">
                        {topic.name} ({topic.questions})
                      </Label>
                    </div>
                  ))}
                </CollapsibleContent>
              </Collapsible>
            ))}
          </ScrollArea>
        </div>
        <Separator />
        <div>
          <h3 className="text-lg font-semibold mb-2">Number of Questions</h3>
          <div className="flex items-center space-x-4">
            <Slider
              value={[questionCount]}
              onValueChange={(value) => setQuestionCount(value[0])}
              max={59}
              min={1}
              step={1}
              className="flex-grow"
            />
            <Input
              type="number"
              value={questionCount}
              onChange={(e) => setQuestionCount(Number(e.target.value))}
              max={59}
              min={1}
              className="w-20"
            />
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <Button 
          className="w-full bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white" 
          onClick={handleCreateTest}
        >
          Create Test
        </Button>
      </CardFooter>
    </Card>
  );
}
