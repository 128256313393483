import React from 'react';
import { cn } from '../../lib/utils';

export function Switch({ id, checked, onCheckedChange }) {
  return (
    <label className="inline-flex items-center cursor-pointer space-x-2">
      <input
        type="checkbox"
        id={id}
        className="sr-only"
        checked={checked}
        onChange={(e) => onCheckedChange(e.target.checked)}
      />
      <div
        className={cn(
          'relative w-11 h-6 rounded-full transition-colors duration-300 ease-in-out',
          checked ? 'bg-blue-600' : 'bg-gray-300' // Blue when on, Gray when off
        )}
      >
        <div
          className={cn(
            'absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow transform transition-transform duration-300 ease-in-out',
            checked ? 'translate-x-5' : 'translate-x-0'
          )}
        ></div>
      </div>
      <span className="text-sm font-medium text-gray-700">
        {checked ? 'On' : 'Off'}
      </span>
    </label>
  );
}
