import React from 'react';

export function RadioGroup({ children, value, onValueChange }) {
  return (
    <div>
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          name: 'radio-group', // Make sure all radio buttons share the same name
          checked: child.props.value === value, // Ensure only one radio button is checked
          onChange: e => onValueChange(e.target.value),
        })
      )}
    </div>
  );
}

export function RadioGroupItem({ id, value, checked, ...props }) {
  return (
    <input
      type="radio"
      id={id}
      value={value}
      checked={checked}
      name="radio-group" // Ensure the name is set for each item
      {...props}
    />
  );
}
