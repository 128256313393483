import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Button } from '../components/ui/button';
import { RadioGroup, RadioGroupItem } from '../components/ui/radio-group';
import { Label } from '../components/ui/label';
import { Textarea } from '../components/ui/textarea';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../components/ui/dialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../components/ui/table';
import { ScrollArea } from '../components/ui/scroll-area';
import { Switch } from '../components/ui/switch';
import {
  Clock,
  Flag,
  ChevronLeft,
  ChevronRight,
  Menu,
  MessageSquare,
  Highlighter,
  Scissors,
  Play,
  Eraser,
  CheckCircle,
  XCircle,
} from 'lucide-react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useAuth0 } from '@auth0/auth0-react';

export default function TestInterface({ isReviewMode: propIsReviewMode = false }) {
  const { testId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth0();
  
  // State variables
  const [passages, setPassages] = useState([]);
  const [currentPassageIndex, setCurrentPassageIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showSolution, setShowSolution] = useState(false);
  const [markups, setMarkups] = useState({});
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [flaggedQuestions, setFlaggedQuestions] = useState([]);
  const [showNavigation, setShowNavigation] = useState(false);
  const [showEndTestConfirmation, setShowEndTestConfirmation] = useState(false);
  const [showTestSummary, setShowTestSummary] = useState(false);
  const [confidenceRatings, setConfidenceRatings] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [lockedQuestions, setLockedQuestions] = useState({});
  
  const currentPassage = passages[currentPassageIndex] || null;
  const currentQuestion = currentPassage?.questions[currentQuestionIndex] || null;
  const contentRef = useRef(null);

  // Determine if we're in review mode based on both prop and URL
  const isReviewMode = propIsReviewMode || location.pathname.includes('/review');

  // Helper Functions
  const getTotalQuestions = useCallback(() => {
    return passages.reduce((total, passage) => total + (passage.questions?.length || 0), 0);
  }, [passages]);

  const getCurrentQuestionNumber = useCallback(() => {
    let questionNumber = currentQuestionIndex + 1;
    for (let i = 0; i < currentPassageIndex; i++) {
      questionNumber += passages[i].questions?.length || 0;
    }
    return questionNumber;
  }, [currentQuestionIndex, currentPassageIndex, passages]);

  const getQuestionRangeForPassage = useCallback((passageIndex) => {
    let startingQuestion = 1;
    for (let i = 0; i < passageIndex; i++) {
      startingQuestion += passages[i].questions.length;
    }
    const endingQuestion = startingQuestion + (passages[passageIndex]?.questions?.length || 0) - 1;
    return { startingQuestion, endingQuestion };
  }, [passages]);

  const isLastQuestion = useCallback(() => {
    return currentPassageIndex === passages.length - 1 && 
           currentQuestionIndex === (currentPassage?.questions.length || 0) - 1;
  }, [currentPassageIndex, currentQuestionIndex, passages, currentPassage]);

  const getActualQuestionNumber = useCallback((passageIndex, questionIndex) => {
    let questionNumber = 1;
    for (let i = 0; i < passageIndex; i++) {
      questionNumber += passages[i].questions.length;
    }
    return questionNumber + questionIndex;
  }, [passages]);

  // Helper function to get the selected range
  const getSelectedRange = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return null;

    const range = selection.getRangeAt(0);
    const startContainer = range.startContainer;
    const endContainer = range.endContainer;

    if (
      !(startContainer.parentElement instanceof HTMLElement) ||
      !(endContainer.parentElement instanceof HTMLElement)
    ) {
      return null;
    }

    const startElement = startContainer.parentElement.closest('[data-offset]');
    const endElement = endContainer.parentElement.closest('[data-offset]');

    if (!startElement || !endElement) return null;

    const elementId = startElement.getAttribute('data-element-id') || '';
    const startOffset =
      parseInt(startElement.getAttribute('data-offset') || '0', 10) +
      range.startOffset;
    const endOffset =
      parseInt(endElement.getAttribute('data-offset') || '0', 10) +
      range.endOffset;

    return {
      range: { start: startOffset, end: endOffset },
      elementId,
    };
  };

  // Function to handle applying/removing markups
  const handleMarkup = useCallback((type) => {
    const selectionInfo = getSelectedRange();
    if (selectionInfo) {
      const { range, elementId } = selectionInfo;
      setMarkups((prev) => {
        const existingMarkups = prev[elementId] || [];
        const updatedMarkups = existingMarkups.filter(
          (markup) =>
            !(
              markup.range.start === range.start &&
              markup.range.end === range.end &&
              markup.type === type
            )
        );

        if (updatedMarkups.length === existingMarkups.length) {
          // If no existing markup of this type, add it
          updatedMarkups.push({ type, range });
        }

        return {
          ...prev,
          [elementId]: updatedMarkups,
        };
      });
    }
  }, []);

  // Hotkeys for Markup
  const handleHighlight = useCallback(() => {
    handleMarkup('highlight');
  }, [handleMarkup]);

  const handleStrikethrough = useCallback(() => {
    handleMarkup('strikethrough');
  }, [handleMarkup]);

  useHotkeys('alt+h', handleHighlight, { preventDefault: true });
  useHotkeys('alt+s', handleStrikethrough, { preventDefault: true });

  // Fetch Test Data
  const fetchTestData = async () => {
    if (!testId || !user) return;

    setIsLoading(true);

    try {
      // Get the Supabase user ID from the auth0_user_mapping table
      const { data: mappingData, error: mappingError } = await supabase
        .from('auth0_user_mapping')
        .select('id')
        .eq('auth0_user_id', user.sub)
        .single();

      if (mappingError) throw mappingError;

      const supabaseUserId = mappingData.id;

      // Fetch the test data
      const { data: testData, error: testError } = await supabase
        .from('tests')
        .select('*')
        .eq('id', testId)
        .eq('user_id', supabaseUserId)
        .single();

      if (testError) throw testError;

      // Now fetch the passages using the passage_ids
      const passageIds = testData.passage_ids;

      const { data: passagesData, error: passagesError } = await supabase
        .from('passages')
        .select(`
          *,
          questions (
            *,
            choices (*)
          )
        `)
        .in('id', passageIds);

      if (passagesError) throw passagesError;

      setPassages(passagesData);

      // If in review mode, fetch selected answers
      if (isReviewMode) {
        const { data: selectedAnswersData, error: selectedAnswersError } = await supabase
          .from('test_answers')
          .select('*')
          .eq('test_id', testId);

        if (selectedAnswersError) throw selectedAnswersError;

        const selectedAnswersObj = {};
        const confidenceRatingsObj = {};
        const flaggedQuestionsArr = [];
        const markupsObj = {};

        selectedAnswersData.forEach(answer => {
          selectedAnswersObj[answer.question_id] = answer.selected_answer;
          confidenceRatingsObj[answer.question_id] = answer.confidence_rating;
          if (answer.flagged) {
            flaggedQuestionsArr.push(answer.question_id);
          }
          markupsObj[`question-${answer.question_id}`] = JSON.parse(answer.question_markups || '[]');
          JSON.parse(answer.choice_markups || '[]').forEach((markup, index) => {
            markupsObj[`choice-${answer.question_id}-${index}`] = markup;
          });
          // Add passage markups
          if (answer.passage_markups) {
            markupsObj[`passage-${answer.passage_id}`] = JSON.parse(answer.passage_markups || '[]');
          }
        });

        setSelectedAnswers(selectedAnswersObj);
        setConfidenceRatings(confidenceRatingsObj);
        setFlaggedQuestions(flaggedQuestionsArr);
        setMarkups(markupsObj);

        // Lock all questions to prevent changes
        const lockedQuestionsObj = {};
        selectedAnswersData.forEach(answer => {
          lockedQuestionsObj[answer.question_id] = true;
        });
        setLockedQuestions(lockedQuestionsObj);

        // Always show solutions in review mode
        setShowSolution(true);
      } else {
        // Initialize test progress
        const { data: progressData, error: progressError } = await supabase
          .from('test_progress')
          .insert({
            user_id: supabaseUserId,
            passage_index: 0,
            question_index: 0,
            elapsed_time: 0
          })
          .select()
          .single();

        if (progressError) throw progressError;
      }

      // Set initial passage and question indices
      setCurrentPassageIndex(0);
      setCurrentQuestionIndex(0);
    } catch (error) {
      console.error('Error fetching test data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTestData();
  }, [testId, user, isReviewMode]);

  useEffect(() => {
    if (isReviewMode) {
      setShowSolution(true);
    }
  }, [isReviewMode]);

  // **New useEffect to reset showSolution when question changes**
  useEffect(() => {
    if (!isReviewMode) {
      setShowSolution(false);
    }
  }, [currentPassageIndex, currentQuestionIndex, isReviewMode]);

  // Handle Answer Selection
  const handleAnswerSelect = (questionId, choiceIndex) => {
    if (isReviewMode || lockedQuestions[questionId]) return;

    setSelectedAnswers(prev => ({
      ...prev,
      [questionId]: choiceIndex
    }));
  };

  // Timer Effect
  useEffect(() => {
    let interval = null;
    if (isTimerRunning) {
      interval = setInterval(() => {
        setElapsedTime(prevTime => prevTime + 1);
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isTimerRunning]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const toggleTimer = () => {
    setIsTimerRunning(prev => !prev);
  };

  // Handle Show Solution
  const handleShowSolution = () => {
    if (!currentQuestion) return;

    if (!showSolution) {
      if (selectedAnswers[currentQuestion.id] === undefined) {
        return;
      }
      setLockedQuestions((prev) => ({
        ...prev,
        [currentQuestion.id]: true,
      }));
    }

    console.log('Current question:', currentQuestion);
    console.log('Correct answer:', currentQuestion.correct_answer);
    console.log('Selected answer:', selectedAnswers[currentQuestion.id]);

    setShowSolution((prev) => !prev);
  };

  // Handle Navigation
  const handleNextQuestion = () => {
    if (currentPassage && currentQuestionIndex < currentPassage.questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    } else if (currentPassageIndex < passages.length - 1) {
      setCurrentPassageIndex(prev => prev + 1);
      setCurrentQuestionIndex(0);
    }
    setShowSolution(false);
    console.log("Moving to next question, new ID:", passages[currentPassageIndex]?.questions[currentQuestionIndex + 1]?.id);
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1);
    } else if (currentPassageIndex > 0) {
      setCurrentPassageIndex(prev => prev - 1);
      setCurrentQuestionIndex(passages[currentPassageIndex - 1].questions.length - 1);
    }
    setShowSolution(false);
    console.log("Moving to previous question, new ID:", passages[currentPassageIndex]?.questions[currentQuestionIndex - 1]?.id);
  };

  const handlePreviousPassage = () => {
    if (currentPassageIndex > 0) {
      setCurrentPassageIndex(prev => prev - 1);
      setCurrentQuestionIndex(0);
      setShowSolution(false);
    }
  };

  const handleNextPassage = () => {
    if (currentPassageIndex < passages.length - 1) {
      setCurrentPassageIndex(prev => prev + 1);
      setCurrentQuestionIndex(0);
      setShowSolution(false);
    }
  };

  useEffect(() => {
    console.log('Current passages:', passages);
    console.log('Current passage:', currentPassage);
    console.log('Current question:', currentQuestion);
  }, [passages, currentPassage, currentQuestion]);

  // Supabase Connection Test
  useEffect(() => {
    const testSupabase = async () => {
      try {
        const { data, error } = await supabase.from('passages').select('count');
        if (error) {
          console.error('Supabase connection error:', error);
        } else {
          console.log('Supabase connection successful, passage count:', data);
        }
      } catch (error) {
        console.error('Error testing Supabase connection:', error);
      }
    };
    testSupabase();
  }, []);

  // State Update Logging
  useEffect(() => {
    console.log('State update:');
    console.log('Passages:', passages);
    console.log('Current Passage Index:', currentPassageIndex);
    console.log('Current Question Index:', currentQuestionIndex);
    console.log('Current Passage:', passages[currentPassageIndex]);
    console.log('Current Question:', passages[currentPassageIndex]?.questions?.[currentQuestionIndex]);
  }, [passages, currentPassageIndex, currentQuestionIndex]);

  // Handle Highlight and Strikethrough via Hotkeys
  // These functions are now properly defined with handleMarkup
  // (Already defined above)

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (passages.length === 0) {
    console.log('No passages available. Current state:', {
      passages,
      currentPassageIndex,
      currentQuestionIndex
    });
    return <div>No passages available in the database. Check console for more information.</div>;
  }

  if (!passages[currentPassageIndex] || !passages[currentPassageIndex].questions[currentQuestionIndex]) {
    console.log('No current passage or question. Current state:', {
      passages,
      currentPassageIndex,
      currentQuestionIndex,
      currentPassage: passages[currentPassageIndex],
      currentQuestion: passages[currentPassageIndex]?.questions?.[currentQuestionIndex]
    });
    return <div>No passages or questions available. Please check the console for more information.</div>;
  }

  if (!currentQuestion) {
    return <div>No question data available. Please check the console for more information.</div>;
  }

  // Handle Markups
  const handleClearMarkups = () => setMarkups({});

  // Updated parseChoiceText to extract label and text
  const parseChoiceText = (text) => {
    const regex = /^([A-Z])\.\s*(.*?)(?:\s*\((\d+)%\))?$/;
    const match = text.match(regex);
    if (match) {
      return {
        label: match[1],
        text: match[2].trim(),
        percentage: match[3] ? match[3] : null,
      };
    }
    return {
      label: '',
      text: text,
      percentage: null,
    };
  };

  // Render with Markup Function (unchanged)
  const renderWithMarkup = (text, elementId, baseOffset = 0) => {
    if (!text) return null;
  
    const elementMarkups = markups[elementId] || [];
    const sortedMarkups = elementMarkups
      .filter(markup => markup.range.start >= baseOffset && markup.range.end <= baseOffset + text.length)
      .sort((a, b) => a.range.start - b.range.start);
  
    let result = [];
    let lastIndex = baseOffset;
  
    for (let i = 0; i < text.length; i++) {
      const currentOffset = baseOffset + i;
      const char = text[i];
  
      // Handle newline characters
      if (char === '\n') {
        result.push(<br key={`br-${currentOffset}`} />);
        continue;
      }
  
      // Optionally handle carriage returns if present
      if (char === '\r') {
        // Skip carriage return if followed by newline
        if (text[i + 1] === '\n') {
          continue;
        }
        result.push(<br key={`br-${currentOffset}`} />);
        continue;
      }
  
      const activeMarkups = sortedMarkups.filter(markup => 
        markup.range.start <= currentOffset && markup.range.end > currentOffset
      );
  
      let content = char;
      if (activeMarkups.some(m => m.type === 'highlight')) {
        content = <mark key={`highlight-${currentOffset}`}>{char}</mark>;
      }
      if (activeMarkups.some(m => m.type === 'strikethrough')) {
        content = <s key={`strikethrough-${currentOffset}`}>{char}</s>;
      }
  
      result.push(
        <span key={`text-${currentOffset}`} data-offset={currentOffset} data-element-id={elementId}>
          {content}
        </span>
      );
  
      lastIndex = currentOffset + 1;
    }
  
    return result;
  };

  // Handle Flagging Questions
  const handleFlagForReview = () => {
    if (!currentQuestion) return;
    setFlaggedQuestions(prev =>
      prev.includes(currentQuestion.id)
        ? prev.filter(id => id !== currentQuestion.id)
        : [...prev, currentQuestion.id]
    );
  };

  // Handle Confidence Rating
  const handleConfidenceRating = (rating) => {
    if (!currentQuestion) return;
    setConfidenceRatings(prev => ({
      ...prev,
      [currentQuestion.id]: rating
    }));
  };

  // Handle Ending Test
  const handleEndTest = () => {
    setShowEndTestConfirmation(true);
  };

  const confirmEndTest = () => {
    setShowEndTestConfirmation(false);
    setShowTestSummary(true);
  };

  const cancelEndTest = () => {
    setShowEndTestConfirmation(false);
  };

  // Finalize Test Submission
  const finalizeTest = async () => {
    setShowTestSummary(false);
    
    if (!user || !testId) {
      console.error('User or testId is missing');
      return;
    }

    console.log('Passages:', passages);

    try {
      // Get the Supabase user ID
      const { data: mappingData, error: mappingError } = await supabase
        .from('auth0_user_mapping')
        .select('id')
        .eq('auth0_user_id', user.sub)
        .single();

      if (mappingError) {
        console.error('Error fetching user mapping:', mappingError);
        throw mappingError;
      }

      const supabaseUserId = mappingData.id;

      // Calculate the score
      const totalQuestions = getTotalQuestions();
      const correctAnswers = Object.entries(selectedAnswers).reduce((sum, [questionId, answerIndex]) => {
        const question = passages.flatMap(p => p.questions).find(q => q.id === parseInt(questionId));
        return sum + (question && question.correct_answer === answerIndex ? 1 : 0);
      }, 0);
      const score = Math.round((correctAnswers / totalQuestions) * 100);

      console.log('Calculated score:', score);

      // Update the test record
      const { error: testUpdateError } = await supabase
        .from('tests')
        .update({
          score: score,
          test_date: new Date().toISOString(), // Use test_date instead of completed_at
        })
        .eq('id', testId);

      if (testUpdateError) {
        console.error('Error updating test record:', testUpdateError);
        throw testUpdateError;
      }

      console.log('Test record updated successfully');

      // Check for existing performance record
      const { data: existingPerformance, error: performanceCheckError } = await supabase
        .from('performance')
        .select('*')
        .eq('user_id', supabaseUserId)
        .eq('subject', passages[0].subject)
        .maybeSingle();

      if (performanceCheckError) {
        console.error('Error checking existing performance:', performanceCheckError);
        throw performanceCheckError;
      }

      const performanceData = {
        user_id: supabaseUserId,
        subject: passages[0].subject,
        total_questions_answered: totalQuestions,
        correct_answers: correctAnswers,
        score: score,
      };

      if (existingPerformance) {
        const { error: performanceUpdateError } = await supabase
          .from('performance')
          .update({
            total_questions_answered: existingPerformance.total_questions_answered + totalQuestions,
            correct_answers: existingPerformance.correct_answers + correctAnswers,
            score: Math.round(((existingPerformance.correct_answers + correctAnswers) / (existingPerformance.total_questions_answered + totalQuestions)) * 100),
          })
          .eq('id', existingPerformance.id);

        if (performanceUpdateError) {
          console.error('Error updating performance record:', performanceUpdateError);
          throw performanceUpdateError;
        }
      } else {
        const { error: performanceInsertError } = await supabase
          .from('performance')
          .insert([performanceData]);

        if (performanceInsertError) {
          console.error('Error inserting performance record:', performanceInsertError);
          throw performanceInsertError;
        }
      }

      console.log('Performance record updated/inserted successfully');

      // Prepare data for test_answers
      const testAnswersData = [];

      passages.forEach((passage) => {
        const passageMarkups = markups[`passage-${passage.id}`] || [];
        
        passage.questions.forEach((question) => {
          const questionId = question.id;
          const selectedAnswer = selectedAnswers[questionId];
          const isCorrect = selectedAnswer === question.correct_answer;
          const confidenceRating = confidenceRatings[questionId] || null;
          const flagged = flaggedQuestions.includes(questionId);
          const questionMarkups = markups[`question-${questionId}`] || [];
          const choiceMarkups = question.choices.map((_, index) => 
            markups[`choice-${questionId}-${index}`] || []
          );

          const answerData = {
            test_id: testId,
            question_id: questionId,
            passage_id: passage.id,
            selected_answer: selectedAnswer,
            is_correct: isCorrect,
            confidence_rating: confidenceRating,
            feedback_text: '', // Update if you collect feedback per question
            flagged: flagged,
            question_markups: JSON.stringify(questionMarkups),
            choice_markups: JSON.stringify(choiceMarkups),
          };

          // Only add passage_markups if it exists in the database schema
          if (passageMarkups.length > 0) {
            answerData.passage_markups = JSON.stringify(passageMarkups);
          }

          testAnswersData.push(answerData);
        });
      });

      // Insert test answers into the database
      const { error: insertTestAnswersError } = await supabase
        .from('test_answers')
        .insert(testAnswersData);

      if (insertTestAnswersError) {
        console.error('Error inserting test answers:', insertTestAnswersError);
        throw insertTestAnswersError;
      }

      // Navigate back to the dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error('Error finalizing test:', error);
      alert(`An error occurred while saving your test results: ${error.message}`);
    }
  };

  const getTotalIncompleteQuestions = () => {
    return passages.reduce((total, passage) => 
      total + passage.questions.filter(q => selectedAnswers[q.id] === undefined).length, 
    0);
  };

  // Handle Feedback Submission
  const handleSubmitFeedback = async () => {
    if (feedbackText.trim() === '') return;

    try {
      // Get the Supabase user ID from the auth0_user_mapping table
      const { data: mappingData, error: mappingError } = await supabase
        .from('auth0_user_mapping')
        .select('id')
        .eq('auth0_user_id', user.sub)
        .single();

      if (mappingError) throw mappingError;

      const supabaseUserId = mappingData.id;

      // Insert feedback into the 'feedback' table
      const { error: insertError } = await supabase
        .from('feedback')
        .insert([
          {
            user_id: supabaseUserId,
            test_id: testId, // Optional: Include if feedback is tied to a specific test
            feedback_text: feedbackText,
          }
        ]);

      if (insertError) throw insertError;

      // Success feedback to user
      alert('Feedback submitted. Thank you!');
      setFeedbackText('');
      setShowFeedbackDialog(false);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert(`An error occurred while submitting your feedback: ${error.message}`);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100 font-[Arial]">
      {/* Header */}
      <header className="bg-[#0066cc] text-white p-2 flex justify-between items-center">
        <h1 className="text-lg font-bold">
          {isReviewMode ? "Test Review" : "Medical College Admission Test"} - {user?.name || 'Guest'}
        </h1>
        <div className="flex items-center space-x-4">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={toggleTimer} 
            className="text-white hover:bg-white hover:text-[#0066cc] transition-colors duration-200 flex items-center space-x-2"
          >
            <Clock className="w-4 h-4 mr-1" />
            <span>{formatTime(elapsedTime)}</span>
            {!isTimerRunning && <Play className="w-4 h-4 ml-1" />}
          </Button>
          <span className="text-sm">
            Question {getCurrentQuestionNumber()} of {getTotalQuestions()}
          </span>
        </div>
      </header>

      {/* Toolbar */}
      <div className="bg-[#4a90e2] text-white p-2 flex justify-between items-center">
        <div className="flex space-x-4">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={handleHighlight} 
            className="text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200" 
            disabled={isReviewMode}
          >
            <Highlighter className="w-4 h-4 mr-1" />
            Highlight
          </Button>
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={handleStrikethrough} 
            className="text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200" 
            disabled={isReviewMode}
          >
            <Scissors className="w-4 h-4 mr-1" />
            Strikethrough
          </Button>
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={handleClearMarkups} 
            className="text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200" 
            disabled={isReviewMode}
          >
            <Eraser className="w-4 h-4 mr-1" />
            Clear
          </Button>
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={() => setShowFeedbackDialog(true)} 
            className="text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200" 
            disabled={isReviewMode}
          >
            <MessageSquare className="w-4 h-4 mr-1" />
            Feedback
          </Button>
        </div>
        <Button
          variant="ghost"
          size="sm"
          onClick={handleFlagForReview}
          className={`text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200 ${
            flaggedQuestions.includes(currentQuestion?.id) ? "bg-white text-[#4a90e2]" : ""
          }`}
          disabled={isReviewMode}
        >
          <Flag className={`w-4 h-4 mr-1 ${flaggedQuestions.includes(currentQuestion?.id) ? "text-yellow-400" : ""}`} />
          {flaggedQuestions.includes(currentQuestion?.id) ? "Flagged" : "Flag for Review"}
        </Button>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex overflow-hidden" ref={contentRef}>
        
        {/* Passage Section */}
        <div className="w-1/2 p-4 overflow-y-auto">
          <div className="p-4">
            {/* Passage Navigation */}
            <div className="flex justify-between items-center mb-4">
              <span 
                onClick={handlePreviousPassage}
                className={`cursor-pointer ${currentPassageIndex === 0 ? 'text-gray-400' : 'text-blue-600 hover:text-blue-800'}`}
              >
                {currentPassageIndex > 0 && '← Previous Passage'}
              </span>
              <span 
                onClick={handleNextPassage}
                className={`cursor-pointer ${currentPassageIndex === passages.length - 1 ? 'text-gray-400' : 'text-blue-600 hover:text-blue-800'}`}
              >
                {currentPassageIndex < passages.length - 1 && 'Next Passage →'}
              </span>
            </div>

            {/* Passage Title */}
            <h2 className="text-xl font-bold mb-4">
              {passages.length > 0 && currentPassage && (
                <>
                  Passage {currentPassageIndex + 1} (Questions {getQuestionRangeForPassage(currentPassageIndex).startingQuestion} - {getQuestionRangeForPassage(currentPassageIndex).endingQuestion})
                </>
              )}
            </h2>

            {/* Passage Content */}
            <div className="whitespace-pre-wrap font-['Helvetica','Arial',sans-serif] text-[16px] leading-tight space-y-0.5 mb-6">
              {renderWithMarkup(currentPassage.content, `passage-${currentPassage.id}`)}
            </div>

            

            {/* Passage Title (Optional) */}
            <p className="text-sm text-gray-500 mt-4">{currentPassage.title}</p>

            {/* Confidence Rating Section */}
            <div className="mt-4 p-4 bg-gray-200 rounded">
              <h3 className="text-lg font-semibold mb-2">Confidence Rating</h3>
              <div className="flex space-x-2">
                {[
                  { label: 'Low', bgColor: 'bg-red-600', lightBgColor: 'bg-red-100', textColor: 'text-red-600', hoverBg: 'hover:bg-red-700', hoverText: 'hover:bg-red-200' },
                  { label: 'Medium', bgColor: 'bg-yellow-600', lightBgColor: 'bg-yellow-100', textColor: 'text-yellow-600', hoverBg: 'hover:bg-yellow-700', hoverText: 'hover:bg-yellow-200' },
                  { label: 'High', bgColor: 'bg-green-600', lightBgColor: 'bg-green-100', textColor: 'text-green-600', hoverBg: 'hover:bg-green-700', hoverText: 'hover:bg-green-200' }
                ].map(({ label, bgColor, lightBgColor, textColor, hoverBg, hoverText }) => (
                  <Button
                    key={label}
                    variant={confidenceRatings[currentQuestion?.id] === label ? 'default' : 'outline'}
                    onClick={() => handleConfidenceRating(label)}
                    className={`w-full ${
                      confidenceRatings[currentQuestion?.id] === label
                        ? `${bgColor} text-white ${hoverBg}`
                        : `${lightBgColor} ${textColor} ${hoverText}`
                    }`}
                    disabled={isReviewMode}
                  >
                    {label}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Question Section */}
        <div className="w-1/2 p-4 overflow-y-auto">
          <div className="p-4">
            {/* Question Title */}
            <h3 className="text-lg font-bold mb-4">
              Question {getActualQuestionNumber(currentPassageIndex, currentQuestionIndex)}
            </h3>

            {/* Question Text */}
            <p className="mb-4 font-['Helvetica','Arial',sans-serif] text-[16px] leading-snug">
              {renderWithMarkup(currentQuestion.text, `question-${currentQuestion.id}`)}
            </p>

            {/* Answer Choices */}
            <RadioGroup
              key={`radio-group-${currentQuestion?.id}`}
              value={
                selectedAnswers[currentQuestion?.id] !== undefined
                  ? selectedAnswers[currentQuestion.id]?.toString()
                  : ''
              }
              onValueChange={(value) => handleAnswerSelect(currentQuestion.id, parseInt(value))}
            >
              {currentQuestion.choices?.map((choice, index) => {
                const { label, text, percentage } = parseChoiceText(choice.text || '');

                // Determine if this choice is the correct answer
                const isCorrect = index === currentQuestion.correct_answer;

                // Determine if this choice is the user's selected answer
                const isSelected = selectedAnswers[currentQuestion.id] === index;

                // Determine if we should show solution (either via showSolution or in review mode)
                const shouldShowSolution = showSolution || isReviewMode;

                // Determine styling based on correctness and selection
                let choiceStyle = "border border-gray-300";

                if (shouldShowSolution) {
                  if (isCorrect) {
                    choiceStyle = "border-2 border-green-500 bg-green-100";
                  }
                  if (isSelected && !isCorrect) {
                    choiceStyle = "border-2 border-red-500 bg-red-100";
                  }
                }

                return (
                  <div key={index} className={`flex items-center space-x-2 mb-4 p-2 rounded ${shouldShowSolution ? choiceStyle : ""}`}>
                    <RadioGroupItem
                      value={index.toString()}
                      id={`q${currentQuestion.id}-choice${index}`}
                      checked={isSelected}
                      disabled={isReviewMode || lockedQuestions[currentQuestion.id]}
                      className="mt-1"
                    />
                    <Label htmlFor={`q${currentQuestion.id}-choice${index}`} className="font-['Helvetica','Arial',sans-serif] text-[16px] text-gray-900">
                      {/* Render the label in bold and the text normally */}
                      {label && (
                        <>
                          <strong>{label}.</strong> {renderWithMarkup(text, `choice-${currentQuestion.id}-${index}`)}
                        </>
                      )}
                      {!label && renderWithMarkup(text, `choice-${currentQuestion.id}-${index}`)}
                      {showSolution && percentage && <span> ({percentage}%)</span>}
                      {/* Icons to indicate correctness */}
                      {shouldShowSolution && isCorrect && (
                        <CheckCircle className="w-4 h-4 text-green-500 ml-2" aria-label="Correct Answer" />
                      )}
                      {shouldShowSolution && isSelected && !isCorrect && (
                        <XCircle className="w-4 h-4 text-red-500 ml-2" aria-label="Incorrect Selection" />
                      )}
                    </Label>
                  </div>
                );
              }) || <div>No choices available for this question.</div>}
            </RadioGroup>

            {/* Action Buttons */}
            <div className="mt-4 space-x-2">
              {!isReviewMode && (
                <Button 
                  onClick={handleShowSolution} 
                  disabled={!showSolution && selectedAnswers[currentQuestion?.id] === undefined}
                  className="text-white bg-[#0066cc] hover:bg-[#0052a3] disabled:bg-gray-400 disabled:cursor-not-allowed"
                >
                  {showSolution ? "Hide Solution" : "Show Solution"}
                </Button>
              )}
              
            </div>

            {/* Unified Explanation Block */}
            {(showSolution || isReviewMode) && (
              <div className="mt-6 p-4 bg-blue-50 border border-blue-200 rounded shadow-md">
                <h4 className="text-lg font-semibold mb-2 text-blue-800">Explanation:</h4>
                <p className="text-gray-700 leading-relaxed">
                  {currentQuestion.question_explanation
                    ? renderWithMarkup(currentQuestion.question_explanation, `question-explanation-${currentQuestion.id}`)
                    : "No explanation available for this question."}
                </p>
              </div>
            )}

            
          </div>
        </div>
      </div>


      {/* Footer with Navigation Buttons */}
      <footer className="bg-gray-200 p-2 flex justify-end items-center">
        {isReviewMode ? (
          <div className="w-full flex justify-between items-center">
            <Button 
              variant="default" 
              onClick={() => navigate('/dashboard')}
              className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
            >
              Back to Dashboard
            </Button>
            <div className="flex space-x-2">
              <Button variant="default" onClick={handlePreviousQuestion} className="text-white bg-[#0066cc] hover:bg-[#0052a3]">
                <ChevronLeft className="w-4 h-4 mr-1" /> Previous
              </Button>
              <Button variant="default" onClick={() => setShowNavigation(true)} className="text-white bg-[#0066cc] hover:bg-[#0052a3]">
                <Menu className="w-4 h-4 mr-1" /> Navigation
              </Button>
              <Button 
                variant="default" 
                onClick={handleNextQuestion}
                disabled={isLastQuestion()}
                className="text-white bg-[#0066cc] hover:bg-[#0052a3] disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                Next <ChevronRight className="w-4 h-4 ml-1" />
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex space-x-2">
            <Button variant="default" onClick={handlePreviousQuestion} className="text-white bg-[#0066cc] hover:bg-[#0052a3]">
              <ChevronLeft className="w-4 h-4 mr-1" /> Previous
            </Button>
            <Button variant="default" onClick={() => setShowNavigation(true)} className="text-white bg-[#0066cc] hover:bg-[#0052a3]">
              <Menu className="w-4 h-4 mr-1" /> Navigation
            </Button>
            {isLastQuestion() ? (
              <Button 
                variant="default" 
                onClick={handleEndTest} 
                className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              >
                End Test
              </Button>
            ) : (
              <Button 
                variant="default" 
                onClick={handleNextQuestion} 
                className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              >
                Next <ChevronRight className="w-4 h-4 ml-1" />
              </Button>
            )}
          </div>
        )}
      </footer>

      {/* Navigation Dialog */}
      <Dialog open={showNavigation} onOpenChange={setShowNavigation} id="navigation-dialog">
        <DialogContent className="sm:max-w-[800px]" style={{
          backgroundColor: 'white',
          borderRadius: '6px',
          boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          maxWidth: '800px',
          maxHeight: '85vh',
          padding: '25px',
        }}>
          <DialogHeader>
            <DialogTitle className="flex items-center justify-between">
              <div className="flex items-center">
                <Menu className="w-5 h-5 mr-2" />
                Navigation - Select a question to go to it
              </div>
            </DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[400px] mt-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Question</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Flagged For Review</TableHead>
                  <TableHead>Confidence</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {passages.flatMap((passage, passageIndex) =>
                  passage.questions.map((question, questionIndex) => {
                    const actualQuestionNumber = getActualQuestionNumber(passageIndex, questionIndex);
                    return (
                      <TableRow
                        key={question.id}
                        className={currentPassageIndex === passageIndex && currentQuestionIndex === questionIndex ? "bg-yellow-100" : ""}
                      >
                        <TableCell>
                          <Button
                            variant="link"
                            onClick={() => {
                              setCurrentPassageIndex(passageIndex);
                              setCurrentQuestionIndex(questionIndex);
                              setShowNavigation(false);
                            }}
                          >
                            Question {actualQuestionNumber}
                          </Button>
                        </TableCell>
                        <TableCell className={selectedAnswers[question.id] !== undefined ? "text-green-500" : "text-red-500"}>
                          {selectedAnswers[question.id] !== undefined ? "Complete" : "Incomplete"}
                        </TableCell>
                        <TableCell>
                          {flaggedQuestions.includes(question.id) && <Flag className="h-4 w-4 text-yellow-500" />}
                        </TableCell>
                        <TableCell>
                          {confidenceRatings[question.id] || 'Not set'}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </ScrollArea>
          <DialogFooter className="flex justify-between items-center">
            <div>{getTotalIncompleteQuestions()} Unseen/Incomplete</div>
            <Button onClick={() => setShowNavigation(false)} className="text-white bg-[#0066cc] hover:bg-[#0052a3]">Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Feedback Dialog */}
      <Dialog open={showFeedbackDialog} onOpenChange={setShowFeedbackDialog}>
        <DialogContent style={{
          backgroundColor: 'white',
          borderRadius: '6px',
          boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          maxWidth: '450px',
          maxHeight: '85vh',
          padding: '25px',
        }}>
          <DialogHeader>
            <DialogTitle>Provide Feedback</DialogTitle>
          </DialogHeader>
          <Textarea
            placeholder="Enter your feedback here..."
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
          />
          <DialogFooter>
            <Button 
              onClick={handleSubmitFeedback}
              className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              disabled={feedbackText.trim() === ''}
            >
              Submit Feedback
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* End Test Confirmation Dialog */}
      <Dialog open={showEndTestConfirmation} onOpenChange={setShowEndTestConfirmation}>
        <DialogContent style={{
          backgroundColor: 'white',
          borderRadius: '6px',
          boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          maxWidth: '450px',
          maxHeight: '85vh',
          padding: '25px',
        }}>
          <DialogHeader>
            <DialogTitle>End Test Confirmation</DialogTitle>
          </DialogHeader>
          <p>Are you sure you want to end this test?</p>
          <DialogFooter>
            <Button variant="outline" onClick={cancelEndTest}>Cancel</Button>
            <Button onClick={confirmEndTest} className="text-white bg-[#0066cc] hover:bg-[#0052a3]">End Test</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Test Summary Dialog */}
      <Dialog open={showTestSummary} onOpenChange={setShowTestSummary}>
        <DialogContent className="sm:max-w-[800px]" style={{
          backgroundColor: 'white',
          borderRadius: '6px',
          boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          maxWidth: '800px',
          maxHeight: '85vh',
          padding: '25px',
        }}>
          <DialogHeader>
            <DialogTitle>Test Summary</DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[400px] mt-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Question</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Flagged For Review</TableHead>
                  <TableHead>Confidence</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {passages.flatMap((passage, passageIndex) =>
                  passage.questions.map((question, questionIndex) => (
                    <TableRow
                      key={question.id}
                      className={
                        currentPassageIndex === passageIndex &&
                        currentQuestionIndex === questionIndex
                          ? "bg-yellow-100"
                          : ""
                      }
                    >
                      <TableCell>Question {getActualQuestionNumber(passageIndex, questionIndex)}</TableCell>
                      <TableCell className={selectedAnswers[question.id] !== undefined ? "text-green-500" : "text-red-500"}>
                        {selectedAnswers[question.id] !== undefined ? "Complete" : "Incomplete"}
                      </TableCell>
                      <TableCell>
                        {flaggedQuestions.includes(question.id) && <Flag className="h-4 w-4 text-yellow-500" />}
                      </TableCell>
                      <TableCell>{confidenceRatings[question.id] || 'Not set'}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </ScrollArea>
          <DialogFooter className="flex justify-between items-center">
            <div>{getTotalIncompleteQuestions()} Unseen/Incomplete</div>
            <Button onClick={finalizeTest} className="text-white bg-[#0066cc] hover:bg-[#0052a3]">End</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
