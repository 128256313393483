// src/components/ui/button.tsx
import React from 'react';
import { cn } from '../../lib/utils';

export const Button = React.forwardRef(
  ({ className, variant = 'default', size = 'md', ...props }, ref) => {
    const baseStyles =
      'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus:outline-none';
    const variantStyles = {
      default: 'bg-blue-600 text-white hover:bg-blue-700',
      ghost: 'bg-transparent hover:bg-gray-100',
      outline: 'border border-gray-300 text-gray-700 hover:bg-gray-100',
      white: 'bg-white text-blue-600 hover:bg-blue-100',
    };
    const sizeStyles = {
      sm: 'px-2 py-1',
      md: 'px-4 py-2',
      lg: 'px-6 py-3 text-base',
    };

    return (
      <button
        ref={ref}
        className={cn(baseStyles, variantStyles[variant], sizeStyles[size], className)}
        {...props}
      />
    );
  }
);

Button.displayName = 'Button';
